export const SCENE_NAMES = [
  // 'intro',
  "who",
  "details",
  "marketplace",
  "pets",
  "weapons",
  "magic-items",
  "open",
  "ecosystem",
  "quest-map",
  "advisors",
  "team",
  "faqs",
  "gallery",
  // 'footer',
]

export const TECH_MODAL_NAMES = {
  equipment: "pets",
  gemz: "marketplace",
  nft: "open",
}