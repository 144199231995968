import React from "react"
import { useLocomotiveScroll } from "react-locomotive-scroll"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import VideoBlob from "../components/VideoBlob"
import { useTheme } from "next-themes"
import { SCENE_NAMES } from "../lib/frontend-const"
import { checkIfFirefox } from "../lib/frontend-helpers"

const HomeFooter = () => {
  const [fillColor, setFillColor] = useState("#1D1A25")
  const [modalLoaded, setModalLoaded] = useState(false)
  const [oppositeTheme, setOppositeTheme] = useState("dark")
  const { scroll } = useLocomotiveScroll()
  const { theme } = useTheme()
  const SSR = typeof window === "undefined"
  let router = useRouter()

  useEffect(() => {
    if (!theme) return
    setOppositeTheme(theme === "dark" ? "light" : "dark")
    setFillColor(theme === "light" ? "#9BC1C9" : "#AB5656")
  }, [theme])

  useEffect(() => {
    if (SSR) return
    if (scroll === null || router.isReady === false) return

    setTimeout(() => {
      scroll.on("call", (eName, when, what) => {
        // console.log(eName, when, what)
        if (when === "exit") return

        if (eName === "sceneChange") {
          const newScene = what.el.getAttribute("attr-scene") || ""

          // let modals deal with changes
          const uri = router.query?.hscene
          if (
            modalLoaded === false &&
            uri && 
            uri.length === 2 &&
            uri[0] === "technical"
          ) {
            setModalLoaded(true)
            return
          }

          if (SCENE_NAMES.includes(newScene)) {
            router.push(`${newScene}`, undefined, { shallow: true })
          } else {
            router.push("", undefined, { shallow: true })
          }
        }
      })
    }, 3000)

    gsap.registerPlugin(ScrollTrigger)

    scroll.on("scroll", ScrollTrigger.update)

    ScrollTrigger.scrollerProxy(".scroll-container", {
      scrollTop(value) {
        return arguments.length
          ? scroll.scrollTo(value, 0, 0)
          : scroll.scroll.instance.scroll.y
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector(".scroll-container").style.transform
        ? "transform"
        : "fixed",
    })

    // where to put in site animations
    if (checkIfFirefox(window) === false) {
      console.log('NOT FF')
      gsap.set(".team-member-contents", {
        scale: 0,
      })
      gsap.to(".team-member-contents", {
        duration: 1.2,
        autoAlpha: 1,
        scale: 1,
        ease: "power2.out",
        // stagger: {
        //   grid: "auto",
        //   from: "random",
        //   each: .05
        // },
        // onUpdate() {
        //   console.log("Update")
        // },
        scrollTrigger: {
          scroller: ".scroll-container",
          trigger: "#p-team",
          start: "top bottom-=100",
          end: "bottom top+=100",
          toggleActions: "play reset play reset",
        },
      })
  
      gsap.set(".emporium-card .card-outer", {
        rotationY: 180,
      })
      gsap.to(".emporium-card .card-outer", {
        duration: 1.2,
        // delay: 0.5,
        rotationY: 0,
        ease: "power2.out",
        stagger: {
          grid: "auto",
          from: "random",
          each: 0.2,
        },
        scrollTrigger: {
          scroller: ".scroll-container",
          trigger: "#scene-marketplace",
          start: "top bottom-=100",
          end: "bottom top+=100",
          toggleActions: "play reset play reset",
        },
      })
  
      gsap.set(".ecosystem-card .card-outer", {
        rotationY: 180,
      })
      gsap.to(".ecosystem-card .card-outer", {
        duration: 1.2,
        // delay: 0.5,
        rotationY: 0,
        ease: "power2.out",
        stagger: {
          grid: "auto",
          from: "random",
          each: 0.2,
        },
        scrollTrigger: {
          scroller: ".scroll-container",
          trigger: "#scene-ecosystem",
          start: "top bottom-=100",
          end: "bottom top+=100",
          toggleActions: "play reset play reset",
        },
      })
    }

    // each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll.
    ScrollTrigger.addEventListener("refresh", () => {
      scroll.update()
    })

    // after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
    ScrollTrigger.refresh()
  }, [scroll, router.isReady])

  return (
    <div
      data-scroll-section
      className="relative z-20 w-full overflow-hidden py-20 text-center text-white 3xl:py-[15vw] vld:md:min-h-screen"
      style={{ backgroundColor: `${fillColor}` }}
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="footer"
        id="scene-footer"
      />

      <img
        data-scroll
        data-scroll-speed="1.4"
        className="absolute top-32 -right-[1%] z-20 w-[18%] dark:w-[8%]"
        src={`/sky-${theme}-3.webp`}
        alt=""
      />
      <img
        data-scroll
        data-scroll-speed="2"
        className="absolute bottom-[21vmin] left-[4%] w-[17%] dark:w-[7%]"
        src={`/sky-${theme}-1.webp`}
        alt=""
      />
      <div
        className="relative z-20 flex h-full w-full items-center justify-center"
        data-scroll
      >
        <div>
          <h1 className="text-title relative z-20 pb-10 leading-[1] md:tracking-[-8px] lg:text-[13vmax]">
            Join our <br />
            community
          </h1>
          <p className="text-summary mx-auto px-4 pb-16 font-sans opacity-80 md:w-[40%] md:px-0">
            Our Discord is currently invite only. Follow and reach out to us on
            Twitter.
          </p>
          <a
            href="https://twitter.com/magicfolk_nft"
            target="_blank"
            className="mx-auto mb-16 inline-block rounded-3xl bg-black px-10 py-4 font-sans font-bold uppercase text-white md:mb-32"
          >
            <svg
              className="relative -left-[10px] -top-[2px] inline-block w-[1.5vmax]"
              viewBox="0 0 20 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 2.01254C19.435 2.88347 18.757 3.62131 17.9659 4.22607C17.9659 4.28491 17.973 4.37726 17.9873 4.5031C18.0016 4.62894 18.0088 4.72128 18.0088 4.78013C18.0088 5.93125 17.8463 7.08237 17.5214 8.2335C17.1964 9.38462 16.7021 10.4841 16.0383 11.5321C15.3746 12.58 14.5872 13.5097 13.676 14.3214C12.7649 15.133 11.6703 15.7823 10.3922 16.2694C9.11411 16.7565 7.74048 17 6.27132 17C3.98289 17 1.89245 16.3581 0 15.0744C0.395559 15.1038 0.720498 15.1185 0.974817 15.1185C2.88156 15.1185 4.58359 14.5135 6.08091 13.3036C5.19101 13.2886 4.39643 13.0046 3.69716 12.4514C2.99789 11.8983 2.50701 11.1862 2.22453 10.3153C2.46455 10.3741 2.72581 10.4036 3.00829 10.4036C3.38955 10.4036 3.7498 10.3445 4.08903 10.2263C3.14281 10.0199 2.35884 9.52562 1.73712 8.74342C1.11541 7.96122 0.804549 7.0611 0.804549 6.04306V5.99892C1.34135 6.29406 1.9557 6.45634 2.6476 6.48576C2.09694 6.1019 1.65914 5.5965 1.3342 4.96957C1.00926 4.34263 0.846791 3.66748 0.846791 2.94412C0.846791 2.20628 1.02334 1.48315 1.37644 0.774733C2.40758 2.11779 3.65405 3.17657 5.11584 3.95107C6.57763 4.72558 8.16312 5.15719 9.8723 5.24592C9.77352 4.877 9.72413 4.55244 9.72413 4.27224C9.72413 3.09169 10.1266 2.08452 10.9316 1.25071C11.7366 0.416903 12.7183 0 13.8768 0C14.4418 0 14.9855 0.118145 15.508 0.354435C16.0305 0.590726 16.4755 0.922754 16.8429 1.35052C17.7752 1.15859 18.6439 0.811852 19.4489 0.310301C19.1521 1.31386 18.5519 2.1033 17.6481 2.67864C18.5098 2.56049 19.2938 2.33914 20 2.01458V2.01254Z"
                fill="#FCFAF7"
              />
            </svg>
            Twitter
          </a>
        </div>
      </div>
      <VideoBlob
        autoPlay={true}
        videoSrc={`/footer-${theme}`}
        oppositeVideoSrc={`/footer-${oppositeTheme}`}
        classNames="absolute -bottom-[4vh] -right-[16vmin] z-10 vpt:w-[55%] vld:w-[45%]"
      />
    </div>
  )
}

export default HomeFooter
