import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import solidity from "react-syntax-highlighter/dist/cjs/languages/prism/solidity"
import prism from "react-syntax-highlighter/dist/cjs/styles/prism/prism"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"

const ModalDialog = (props) => {
  SyntaxHighlighter.registerLanguage("solidity", solidity)

  const { isOpen, setIsOpen } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div
            className="fixed inset-0 top-[6%] z-50 mx-auto h-[88%] w-[60%] max-w-[960px] overflow-hidden rounded-2xl px-12 text-left font-sans text-white shadow-xl"
            style={{
              background: "rgba(25, 23, 28, 0.98)",
              border: "48px solid rgba(0,0,0,0)",
            }}
          >
            <div className="h-full w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="opacity-96 flex h-full w-full transform flex-col transition-all">
                  <Dialog.Title
                    as="h3"
                    className="relative pb-10 pt-2 text-[2.2vmin]"
                  >
                    Technical Specs — Equipment
                    <button
                      type="button"
                      className="
                          absolute 
                          top-4
                          right-0
                          inline-flex
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-black
                          px-4
                          py-2 
                          text-sm 
                          font-medium 
                          text-gray-200 
                          opacity-50
                          transition-opacity
                          hover:text-white
                          hover:opacity-80
                          focus:outline-none "
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </Dialog.Title>
                  <div className="relative w-full overflow-y-auto">
                    <div className="mt-2">
                      <h1
                        id="modal-top"
                        className="mb-6 max-w-[660px] border-b-[1px] border-gray-600 pb-4 text-[1.8vmin]"
                      >
                        tl;dr
                      </h1>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        When an item or pet is equipped, it's staked within the
                        Magic Folk NFT contract and wrapped with the Magic Folk
                        NFT that's equipping it. The extra power level boost
                        from the item(s) is then taken into account when gems
                        are claimed.
                      </p>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        Only the owner of the Magic Folk NFT itself can unequip
                        item.
                        <em>
                          If ownership is transferred while the items are
                          equipped, the new owner will be able to unequip the
                          item.
                        </em>
                        .
                      </p>
                      <p className="max-w-[660px] pb-12 leading-[2]">
                        After an item is equipped/unequipped the contract emits
                        an event which is picked up on the backendso our custom
                        CDN (Content Distribution Network) can update the
                        metadata and rebuild the image with the new metadata.
                      </p>
                      <h1 className="mb-6 max-w-[660px] border-b-[1px] border-gray-600 pb-4 text-[1.8vmin]">
                        In-depth/For cool people
                      </h1>
                      <h2 className="pb-6 text-[1.6vmin]">Addresses*</h2>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        Magic Folk address (ERC721):{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          0xa1b200000GenesisPlaceholder
                        </code>
                        <br />
                        $GEMZ ERC20 address (ERC20):
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          0xa1b200000GemsTokenPlaceholder
                        </code>
                      </p>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        Items (ERC1155):
                        <br />
                        Mainhand address:{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          0xa1b200000MainhandItem
                        </code>
                        <br />
                        Offhand address:{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          0xa1b200000OffhandItem
                        </code>
                        <br />
                        Pet address:{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          0xa1b200000PetItem
                        </code>
                      </p>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        <em>
                          *Addresses will be different once deployed to mainnet,
                          and testnet deployments will change regularly, these
                          are just placeholders until mainnet is deployed
                        </em>
                      </p>
                      <h2 className="pb-6 text-[1.6vmin]">Architecture</h2>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        The ERC721 contract implements the
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          ERC1155 Receiver
                        </code>{" "}
                        interface. We use the ERC1155 protocol, meaning that
                        Magic Folk Items are semi fungible.
                      </p>
                      <p className="max-w-[660px] pb-6 leading-[2]">
                        The{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          onERC1155Received()
                        </code>{" "}
                        function essentially serves as the{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          equip()
                        </code>{" "}
                        function:
                      </p>
                      <SyntaxHighlighter language="solidity" style={oneDark}>
                        {`
function onERC1155Received(
  address _operator, 
  address _from, 
  uint256 _id, 
  uint256 _value,
  bytes calldata _data
) external override returns(bytes4) {
  require(_operator == _from, "INVALID_SENDER");
  require(
      msg.sender == address(MAGIC_FOLK_MAINHAND) 
      || msg.sender == address(MAGIC_FOLK_OFFHAND) 
      || msg.sender == address(MAGIC_FOLK_PET), 
      "Can only receive MagicFolkItems"
  );
  
  (uint256 _nftTokenId, Item memory _item) = decodeOwnerIdAndItem(_data);
  require(
      ownerOf(_nftTokenId) == _from, 
      "TOKEN_NOT_OWNED_BY_SENDER"
  );
  require(_item.itemId == _id, "Decoded itemId does not match itemId passed");
  require(_value == 1, "Can only equip one item at once");
  require(
      _item.itemType == MagicFolkItems(msg.sender)._itemType(), 
      "INCORRECT_ITEM_TYPE"
  );

  _equip(_item, _nftTokenId);

  return ERC1155_RECEIVED_VALUE;
}
                        `}
                      </SyntaxHighlighter>

                      <p className="max-w-[660px] pt-6 pb-8 leading-[2]">
                        Upon receipt of an ERC1155 token this functionality
                        asserts that ERC1155 tokens belong to one of three
                        approved contracts and reverts the transaction if this
                        is not the case. Multiple checks are performed to ensure
                        that items are only equipped by their owners and there
                        are no 'double-equips'. An
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          Item
                        </code>{" "}
                        struct is used to represent the slot that the item
                        occupies along with its power level; enums are as memory
                        efficient as integers so they're used within this struct
                        for readability.
                      </p>
                      <p className="max-w-[660px] pb-6 leading-[2]">
                        Un-equipping an item is similar to equipping it:
                      </p>
                      <SyntaxHighlighter language="solidity" style={oneDark}>
                        {`
function unequip(
  address from,
  bytes calldata data
) external {
  require(
      msg.sender == address(MAGIC_FOLK_MAINHAND) 
      || msg.sender == address(MAGIC_FOLK_OFFHAND) 
      || msg.sender == address(MAGIC_FOLK_PET), 
      "INVALID_CALLER"
  );
  (uint256 nftTokenId, Item memory item) = decodeOwnerIdAndItem(data);
  require(ownerOf(nftTokenId) == from); 
  _unequip(item, nftTokenId);

  IERC1155(msg.sender).safeTransferFrom(address(this), from, item.itemId, 1, "");
  emit Unequipped(nftTokenId, item.itemId);
}
                        `}
                      </SyntaxHighlighter>
                      <p className="max-w-[660px] pt-6 pb-8 leading-[2]">
                        The{" "}
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          unequip()
                        </code>{" "}
                        function must be called from one of the three verified
                        contracts, and checks are performed to ensure that only
                        the current owner of the NFT can unequip the items
                        currently equipped. If all the checks are satisfied then
                        the ERC1155 token is transferred from the Magic Folk
                        contract to the NFT's current owner. The event emitted
                        after this is picked up by a listener on our backend so
                        the CDN can have its metadata and image updated.
                      </p>
                      <h2 className="pb-6 text-[1.6vmin]">Extendability</h2>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        ERC1155 contracts are inherently extendable, but we
                        don't just plan on adding to the equipment collections.
                        The store itself will also be extendable! The solution
                        here is pretty simple, we have a function in the $GEMZ
                        ERC20 contract which allows us to grant the
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          BURNER_ROLE
                        </code>{" "}
                        to new contracts. If a contract has this role it can,
                        with the holder's permission, burn a pre-approved amount
                        of $GEMZ in exchange for whatever type of product it
                        handles. Examples to utilize this extendibility feature
                        are for example: Allowlist spots for other partnership
                        projects, subscription tokens for our in-house built
                        tools, or even access tokens for alpha groups.
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
