import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import solidity from "react-syntax-highlighter/dist/cjs/languages/prism/solidity"
import prism from "react-syntax-highlighter/dist/cjs/styles/prism/prism"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"

const ModalDialog = (props) => {
  SyntaxHighlighter.registerLanguage("solidity", solidity)

  const { isOpen, setIsOpen } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div
            className="fixed inset-0 top-[6%] z-50 mx-auto h-[88%] w-[60%] max-w-[960px] overflow-hidden rounded-2xl px-12 text-left font-sans text-white shadow-xl"
            style={{
              background: "rgba(25, 23, 28, 0.98)",
              border: "48px solid rgba(0,0,0,0)",
            }}
          >
            <div className="h-full w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="opacity-96 flex h-full w-full transform flex-col transition-all">
                  <Dialog.Title
                    as="h3"
                    className="relative pb-10 pt-2 text-[2.2vmin]"
                  >
                    Technical Specs — NFTs
                    <button
                      type="button"
                      className="
                          absolute 
                          top-4
                          right-0
                          inline-flex
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-black
                          px-4
                          py-2 
                          text-sm 
                          font-medium 
                          text-gray-200 
                          opacity-50
                          transition-opacity
                          hover:text-white
                          hover:opacity-80
                          focus:outline-none "
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </Dialog.Title>
                  <div className="relative w-full overflow-y-auto">
                    <div className="mt-2">
                      <h1
                        id="modal-top"
                        className="mb-6 max-w-[660px] border-b-[1px] border-gray-600 pb-4 text-[1.8vmin]"
                      >
                        What is an NFT?
                      </h1>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        A Non-Fungible Token (NFT).
                      </p>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        The best example of something fungible is cash
                        (providing it's not traceable). If I have $1 and you
                        have $1 they're the same. We can swap them with each
                        other and it makes absolutely no difference.
                      </p>

                      <p className="mb-8 max-w-[660px] border-l-2 border-gray-200 pl-4 leading-[2]">
                        <em>Technically</em> there are no{" "}
                        <em>truly fungible</em> tokens on the Ethereum
                        blockchain, because if I have one ERC20 token and you
                        have one ERC20 token they will have completely different
                        transaction histories (if you want a truly fungible
                        cryptocurrency you should look into Monero). However, in
                        practice these tokens are quite fungible so there's not
                        much point in referring to them as anything other than
                        fungible for the purposes of this topic.
                      </p>

                      <p className="max-w-[660px] pb-8 leading-[2]">
                        So, if a token is Non-Fungible it means that it's
                        unique. If I own one token from an NFT collection and
                        you own another token from an NFT collection they're
                        completely different. This is why NFTs are used as proof
                        of ownership for unique digital assets, whether that's a
                        humble JPEG or the deed to your house.
                      </p>

                      <p className="max-w-[660px] pb-12 leading-[2]">
                        If you were to create an ERC20 token with 0 decimals and
                        a maximum supply of 1, it would technically be an NFT,
                        although it would be a rather inefficient way to create
                        one in terms of gas usage. That's why we use{" "}
                        <a
                          className="underline"
                          href="http://erc721.org/"
                          target="_blank"
                        >
                          ERC721
                        </a>{" "}
                        contracts instead.
                      </p>

                      <h1 className="mb-6 max-w-[660px] border-b-[1px] border-gray-600 pb-4 text-[1.8vmin]">
                        What is a Magic Folk NFT?
                      </h1>
                      <h2 className="pb-6 text-[1.6vmin]">Addresses*</h2>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        The Magic Folk collection is the first collection from
                        Splash Labs™, and each Magic Folk NFT is your key to the
                        Splash Labs™ ecosystem. Owning one of these is currently
                        the most cost effective way to unlock all future tools
                        and utilities within our expandable ecosystem.
                      </p>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        It's also a really cool piece of art.
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
