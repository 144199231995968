import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"

const ModalDialog = (props) => {
  const { isOpen, setIsOpen, title, content } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 mx-auto flex items-center justify-center  overflow-hidden">
            <div className="quest-modal-bg relative w-[88%] max-w-[1500px] overflow-y-auto rounded-2xl bg-[#F1EFEA] p-4 text-left font-sans text-black shadow-xl md:pl-12 vpt:h-[48%] vpt:max-h-[900px] vld:h-[88%] vld:max-h-[1000px]">
              <div
                className="absolute left-0 top-0 z-10 h-full w-full opacity-10 md:opacity-100"
                style={{
                  backgroundImage: "url(/qm-metaverse--hover.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "42% auto",
                  backgroundPosition: "right center",
                }}
              />
              <div className="relative z-20 h-full w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="opacity-96 flex transform flex-col text-sm transition-all">
                    <Dialog.Title as="h3" className="text-title pb-6 pt-10">
                      Metaverse ⛰
                      <button
                        type="button"
                        className="
                          absolute 
                          top-3
                          right-3
                          w-6
                          focus:outline-none "
                        onClick={closeModal}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.28572 0L0 4.28572L7.71429 12L0 19.7143L4.28572 24L12 16.2857L19.7143 24L24 19.7143L16.2857 12L24 4.28572L19.7143 0L12 7.71429L4.28572 0Z"
                            fill="#1D1A25"
                          />
                        </svg>
                      </button>
                    </Dialog.Title>
                    <div className="relative w-full overflow-y-auto">
                      <div className="md:w-[55%]">
                        <p className="text-summary font-normal">
                          While the Web3 space waits patiently for metaverse
                          platforms to mature, we will facilitate different
                          mediums of interaction and interoperability with the
                          Magic Gallery being first on the list.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Magic Gallery
                          <span className="quest-progress bg-[#74A6F0]">
                            In Progress
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We are creating a unique, fun way to view and interact
                          with your Magic Folk pets, weapons and items.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Interoperability
                          <span className="quest-progress bg-[#F5A23B]">
                            Exploring
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We have our eyes on games such as Galaxy Fight Club,
                          and it will be a top quest to offer as much
                          interoperability with other project like this as
                          possible.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Metaverse Plugins
                          <span className="quest-progress bg-[#F5A23B]">
                            Exploring
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We have explored the multiple options of upcoming
                          metaverse integrations. Be it Sandbox, Substrata,
                          Otherside or any other suitable metaverse, our team is
                          capable and excited to explore these integrations
                          further.
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
