import { useEffect, useState } from "react"
import { checkIfAppleDevice, checkIfIOS } from "../lib/frontend-helpers"
import { useAppContext } from "../context/state"

const VideoBlob = ({ videoSrc, oppositeVideoSrc, classNames, autoPlay }) => {
  const SSR = typeof window === "undefined"
  const [filetype, setFiletype] = useState(".webm")
  const [isIOS, setIsIOS] = useState(false)
  const globalState = useAppContext()
  const { setVideoLoadedCount } = globalState

  useEffect(() => {
    if (SSR) return
    let filetype = '.webm'
    const iOS = checkIfIOS(window)
    const apple = checkIfAppleDevice(window)

    if (iOS === true || apple === true) {
      filetype = '.mov'
    }
    setFiletype(filetype)
    setIsIOS(iOS)
  }, [])

  return (
    <>
      <video
        className={`block ${classNames}`}
        autoPlay={autoPlay || isIOS}
        loop={true}
        muted={true}
        playsInline={true}
        src={`${videoSrc}${filetype}`}
        preload="auto"
        onLoadedData={() => setVideoLoadedCount(globalState.videoLoadedCount + 1)}
        type="video/webm"
      />
      {oppositeVideoSrc && (
        <video
          className="h-1 w-1 opacity-0"
          autoPlay={isIOS}
          src={`${oppositeVideoSrc}${filetype}`}
          preload="auto"
          onLoadedData={() => setVideoLoadedCount(globalState.videoLoadedCount + 1)}
          type="video/webm"
        />
      )}
    </>
  )
}

export default VideoBlob
