import React from "react"
import { useAppContext } from "../context/state"
import { useRef, useEffect } from "react"
import { useInViewEffect } from "react-hook-inview"

const isItSafari = () => {
  const navigator = window.navigator
  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1
  )
}

const HomeIntro = (props) => {
  const { pageStarts, p } = props
  const globalState = useAppContext()
  const { factionChosen } = globalState

  useEffect(() => {
    if (factionChosen === false) return
    document.querySelector(".scroll-container").classList.remove("z-behind")

    document.querySelectorAll(".factions-mf-img").forEach((e) => e.play())
  }, [factionChosen])

  return (
    <div
      className="p-intro relative min-h-[125vh] pb-32 md:pb-0"
      data-scroll-section
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="intro"
      />
      <div className="smoke intro-title relative" data-scroll>
        <h1
          className="
            7xl:text[350px] 
            relative 
            left-1/2
            pt-32 
            pl-4
            font-display
            text-[13vmin] 
            font-bold
            leading-none
            text-white 
            md:pl-0 
            md:pt-[16vmin] 
            md:text-center 
            7xl:pt-[520px] 
            vld:pt-[7vmax] 
            vld:text-[13.5vmax]"
          style={{ transform: "translateX(-50%)" }}
        >
          <span />
          Meet&nbsp;
          <span className="inline-block md:px-10" />
          The
          <span className="inline-block md:px-12" />
          <br />
          Magic&nbsp;
          <span className="inline-block md:px-16" />
          Folk
          <span className="inline-block md:px-4" />
        </h1>
      </div>

      <div
        data-scroll
        className="
            smoke intro-p
            text-summary
            left-[5vw]
            mx-4
            min-w-[200px]
            max-w-[600px] 
            pt-16
            font-sans
            text-white
            opacity-80
            md:absolute
            md:bottom-[29vh]
            md:mx-0 
            md:w-[25%] 
            md:pt-[8vh] 
            7xl:left-[15vw]"
      >
        <p className="pb-6">
          <a href="https://twitter.com/SplashLabs_" target="_blank" className="hover:underline">Splash Labs</a> introduces The Magic Folk collection, the first building
          block of our Web3 brand and ecosystem.
        </p>
        <p>
          Take a wander through the site and discover more about what
          we&rsquo;re building.
        </p>
      </div>
    </div>
  )
}

export default HomeIntro
