import React from "react"
import { useTheme } from "next-themes"
import { useRef, useEffect, useState } from "react"

const iconTwitter = (
  <svg
    className="mr-1 inline-block w-[18px]"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2.01254C19.435 2.88347 18.757 3.62131 17.9659 4.22607C17.9659 4.28491 17.973 4.37726 17.9873 4.5031C18.0016 4.62894 18.0088 4.72128 18.0088 4.78013C18.0088 5.93125 17.8463 7.08237 17.5214 8.2335C17.1964 9.38462 16.7021 10.4841 16.0383 11.5321C15.3746 12.58 14.5872 13.5097 13.676 14.3214C12.7649 15.133 11.6703 15.7823 10.3922 16.2694C9.11411 16.7565 7.74048 17 6.27132 17C3.98289 17 1.89245 16.3581 0 15.0744C0.395559 15.1038 0.720498 15.1185 0.974817 15.1185C2.88156 15.1185 4.58359 14.5135 6.08091 13.3036C5.19101 13.2886 4.39643 13.0046 3.69716 12.4514C2.99789 11.8983 2.50701 11.1862 2.22453 10.3153C2.46455 10.3741 2.72581 10.4036 3.00829 10.4036C3.38955 10.4036 3.7498 10.3445 4.08903 10.2263C3.14281 10.0199 2.35884 9.52562 1.73712 8.74342C1.11541 7.96122 0.804549 7.0611 0.804549 6.04306V5.99892C1.34135 6.29406 1.9557 6.45634 2.6476 6.48576C2.09694 6.1019 1.65914 5.5965 1.3342 4.96957C1.00926 4.34263 0.846791 3.66748 0.846791 2.94412C0.846791 2.20628 1.02334 1.48315 1.37644 0.774733C2.40758 2.11779 3.65405 3.17657 5.11584 3.95107C6.57763 4.72558 8.16312 5.15719 9.8723 5.24592C9.77352 4.877 9.72413 4.55244 9.72413 4.27224C9.72413 3.09169 10.1266 2.08452 10.9316 1.25071C11.7366 0.416903 12.7183 0 13.8768 0C14.4418 0 14.9855 0.118145 15.508 0.354435C16.0305 0.590726 16.4755 0.922754 16.8429 1.35052C17.7752 1.15859 18.6439 0.811852 19.4489 0.310301C19.1521 1.31386 18.5519 2.1033 17.6481 2.67864C18.5098 2.56049 19.2938 2.33914 20 2.01458V2.01254Z"
      fill="#25222a"
    />
  </svg>
)

const HomeAdvisors = (props) => {
  const [fillColor, setFillColor] = useState("#9BC1C9")
  const { theme } = useTheme()

  useEffect(() => {
    setFillColor(theme === "light" ? "#9BC1C9" : "#AB5656")
  }, [theme])

  return (
    <div
      className={`relative bg-${theme} md:py-32 md:px-14`}
      data-scroll-section
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="advisors"
        id="scene-advisors"
      />
      <div
        className="grid min-h-[40vh] max-w-screen-3xl gap-4 rounded-xl p-4 pt-14 pb-24 text-[#25222a] shadow-2xl dark:text-white md:mx-auto md:block md:w-[80%] md:gap-0 md:p-14"
        style={{ backgroundColor: `${fillColor}` }}
        data-scroll
      >
        <div
          className={`text-title relative z-20 pb-16 text-center leading-[1] lg:text-[3.6vmax]`}
        >
          Trusted Advisors
        </div>
        <div className="grid-cols-[30%_70%] items-center md:grid">
          <div className="w-full rounded-xl px-[10%]">
            <div
              className="w-full rounded-full pt-[100%]"
              style={{
                backgroundImage: `url(/advisors/kumobot.webp)`,
                backgroundSize: "100% auto",
              }}
            />
          </div>
          <div className="flex flex-col items-start justify-center">
            <h3 className="pt-2 leading-5">
              <span className="text-title lg:text-[2.7vmax]">kumobot</span>
              <br />
              <span className="font-sans text-lg opacity-60">
                Project Advisor
              </span>
            </h3>
            <p className="text-p max-w-screen-lg pt-2 pb-4 opacity-80 md:pr-[30%]">
              Nikki is the founder of the awesome KumoXWorld community, which in
              the team's opinion is one of the most tightly knit communities on
              ETH. She enjoys coding, drawing, traveling and dancing. 💪🏽
              <br />
              <a
                href="https://twitter.com/nikkisiuhaiart"
                target="_blank"
                className="underline"
              >
                {iconTwitter}Twitter
              </a>
            </p>
          </div>
        </div>
        <div className="flex grid-cols-[70%_30%] flex-col-reverse items-center md:grid">
          <div className="flex flex-col items-end justify-center text-right">
            <h3 className="pt-2 leading-5">
              <span className="text-title lg:text-[2.7vmax]">dollar</span>
              <br />
              <span className="font-sans text-lg opacity-60">
                Project Advisor
              </span>
            </h3>
            <p className="text-p max-w-screen-lg pt-2 pb-10 opacity-80 md:pl-[30%]">
              Dollar is a renowned collector and entrepreneur who has invested
              in and advised for more than a dozen startups in the Web3 sector.
              <br />
              <a
                href="https://mobile.twitter.com/knownasdollar"
                target="_blank"
                className="underline"
              >
                {iconTwitter}Twitter
              </a>
            </p>
          </div>
          <div className="w-full rounded-xl px-[10%] md:mb-10">
            <div
              className="w-full rounded-full pt-[100%]"
              style={{
                backgroundImage: `url(/advisors/dollar.webp)`,
                backgroundSize: "100% auto",
              }}
            />
          </div>
        </div>
        <div className="grid-cols-[30%_70%] items-center md:grid">
          <div className="w-full rounded-xl px-[10%]">
            <div
              className="w-full rounded-full pt-[100%]"
              style={{
                backgroundImage: `url(/advisors/vprofx.webp)`,
                backgroundSize: "100% auto",
              }}
            />
          </div>
          <div className="flex flex-col items-start justify-center">
            <h3 className="pt-2 leading-5">
              <span className="text-title lg:text-[2.7vmax]">VProFX</span>
              <br />
              <span className="font-sans text-lg opacity-60">
                Visuals Advisor
              </span>
            </h3>
            <p className="text-p max-w-screen-lg pt-2 opacity-80 md:pr-[30%]">
              Vincent is a software dev &amp; visual effects artist &amp;
              animator and has over 20 years experience in the film and
              television industry building both live interactive content in
              Unity for use on-set in Virtual Production as well as content VFX
              for post-production. Vince is currently working at Warner Brothers
              Studios in Burbank, California. 🎥
              <br />
              <svg
                className="relative -top-[1px] mr-2 inline-block w-4"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
              </svg>
              <a
                href="https://vprofx.com"
                target="_blank"
                className="underline"
              >
                Website
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href="https://bit.ly/vparkerIMDb"
                target="_blank"
                className="underline"
              >
                IMDb
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href="https://twitter.com/VProFX"
                target="_blank"
                className="underline"
              >
                {iconTwitter}Twitter
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href="https://www.linkedin.com/in/vprofx/"
                target="_blank"
                className="underline"
              >
                LinkedIn
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeAdvisors
