import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"

const ModalDialog = (props) => {
  const { isOpen, setIsOpen, title, content } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 mx-auto flex items-center justify-center  overflow-hidden">
            <div className="quest-modal-bg relative max-h-[1000px] w-[88%] max-w-[1500px] overflow-y-auto rounded-2xl bg-[#F1EFEA] p-4 text-left font-sans text-black shadow-xl md:pl-12 vpt:h-[58%] vld:h-[88%]">
              <div
                className="absolute left-0 top-0 z-10 h-full w-full opacity-10 md:opacity-100"
                style={{
                  backgroundImage: "url(/qm-global-brand--hover.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "42% auto",
                  backgroundPosition: "right center",
                }}
              />
              <div className="relative z-20 h-full w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="opacity-96 flex transform flex-col text-sm transition-all">
                    <Dialog.Title as="h3" className="text-title pb-6 pt-10">
                      Global Branding 🐉
                      <button
                        type="button"
                        className="
                          absolute 
                          top-3
                          right-3
                          w-6
                          focus:outline-none "
                        onClick={closeModal}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.28572 0L0 4.28572L7.71429 12L0 19.7143L4.28572 24L12 16.2857L19.7143 24L24 19.7143L16.2857 12L24 4.28572L19.7143 0L12 7.71429L4.28572 0Z"
                            fill="#1D1A25"
                          />
                        </svg>
                      </button>
                    </Dialog.Title>
                    <div className="relative w-full overflow-y-auto">
                      <div className="pb-10 md:w-[58%]">
                        <p className="text-summary font-normal">
                          We will continue to grow the Magic Folk Franchise by
                          powering an attention engine, driven by a variety of
                          campaigns and initiatives.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Brand Identity designed by top creative agency
                          <span className="quest-progress bg-[#8FC34D]">
                            Complete
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We&rsquo;ve taken no shortcuts, and have collaborated
                          with a <a href="https://www.tofudesign.co/" target="_blank" className="underline">top creative agency</a> to help put together a
                          brand ready for the global stage. Our agency has
                          worked with Panasonic, Somersby and now…Magic Folk! 😉
                        </p>

                        <h3 className="text-subhead pt-6">
                          Merch
                          <span className="quest-progress bg-[#74A6F0]">
                            In Progress
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          With great branding comes great…merch. We&rsquo;ve
                          already been playing around with some mockups,{" "}
                          <a href="/magic-folk--branding.pdf" target="_blank" className="underline">
                            check them out here!
                          </a>
                        </p>

                        <h3 className="text-subhead pt-6">
                          Fractional Share of &ldquo;main character&rdquo; Magic
                          Folk
                          <span className="quest-progress bg-[#F5A23B]">
                            Exploring
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          This really ties in with our licencing quest. We love
                          the idea of giving fractionlized ownership over some
                          "main character assets".
                        </p>

                        <h3 className="text-subhead pt-6">
                          Licencing
                          <span className="quest-progress bg-[#F5A23B]">
                            Exploring
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We&rsquo;d love to see Magic Folk in TV / Movies /
                          Games. We&rsquo;re absolutely pumped to have <a href="https://twitter.com/VProFX" target="_blank" className="underline">VProFX</a> on
                          board to help us explore here.
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
