import { useEffect, useState } from "react"
import { useTheme } from "next-themes"

const team = [
  {
    handle: "Pexx",
    image: "pexx",
    desc: "CGI artist and founder of Pexxstudio",
    role: "Artist",
    twitter: "Pexx41194765",
  },
  {
    handle: "LeonBoss",
    image: "leonboss",
    desc: "Leon - The Professional animator",
    role: "3D Animation / Motion",
    twitter: "WSRT_K",
  },
  {
    handle: "Carne",
    image: "carne",
    desc: "Data analyst turned NFT degen",
    role: "Operations & Graphics",
    twitter: "CTNFTea",
  },
  {
    handle: "EthApollo",
    image: "ethapollo",
    desc: "Caffeinated marketer with a passion for learning languages.",
    role: "Marketing & PM",
    twitter: "ethapollo_eth",
  },
  {
    handle: "Mr PBH",
    image: "mr-pbh",
    desc: "Degenerate gambler with a passion for writing code and losing money.",
    role: "Blockchain Dev",
    twitter: "realmrpbh",
  },
  {
    handle: "zeroknots",
    image: "zeroknots",
    desc: "Too cool to write a bio.",
    role: "CTO and Dev",
    twitter: "zeroknots1",
  },
  {
    handle: "TR0GD0R",
    image: "trogdor",
    desc: "Like the site? I built it! 🤓",
    role: "Full Stack Dev",
    twitter: "TR000GD0R",
  },
  {
    handle: "TJ",
    image: "tj",
    desc: "Traveling the globe on JPEG'S",
    role: "Community Manager",
    twitter: "nftimmmy",
  },
  {
    handle: "JPEG Josh",
    image: "jpeg-josh",
    desc: "Lover of Music, Travel and JPEG's. It's in the name.",
    role: "Community Manger",
    twitter: "JPEGGINGTON",
  },
  {
    handle: "Ddot",
    image: "ddot",
    desc: "A Community Fren & Social Media Guru with a loud mouth.",
    role: "Marketing Strategist/Lead Mod",
    twitter: "DevversETH",
  },
  {
    handle: "Mitchs",
    image: "mitchs",
    desc: "Loves playing pool and going to music festivals",
    role: "Social Media Manager",
    twitter: "mitchs_nft",
  },
  {
    handle: "Robroy",
    image: "robroy",
    desc: "Loves long walks on the beach when not trading Jpegs",
    role: "Collaboration Manager",
    twitter: "NFTRobRoy",
  },
]

const HomeTeam = () => {
  const [fillColor, setFillColor] = useState("#1D1A25")
  const { theme } = useTheme()

  useEffect(() => {
    setFillColor(theme === "light" ? "#1D1A25" : "#fff")
  }, [theme])

  const iconTwitter = (
    <svg
      className="mr-1 inline-block w-[18px]"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2.01254C19.435 2.88347 18.757 3.62131 17.9659 4.22607C17.9659 4.28491 17.973 4.37726 17.9873 4.5031C18.0016 4.62894 18.0088 4.72128 18.0088 4.78013C18.0088 5.93125 17.8463 7.08237 17.5214 8.2335C17.1964 9.38462 16.7021 10.4841 16.0383 11.5321C15.3746 12.58 14.5872 13.5097 13.676 14.3214C12.7649 15.133 11.6703 15.7823 10.3922 16.2694C9.11411 16.7565 7.74048 17 6.27132 17C3.98289 17 1.89245 16.3581 0 15.0744C0.395559 15.1038 0.720498 15.1185 0.974817 15.1185C2.88156 15.1185 4.58359 14.5135 6.08091 13.3036C5.19101 13.2886 4.39643 13.0046 3.69716 12.4514C2.99789 11.8983 2.50701 11.1862 2.22453 10.3153C2.46455 10.3741 2.72581 10.4036 3.00829 10.4036C3.38955 10.4036 3.7498 10.3445 4.08903 10.2263C3.14281 10.0199 2.35884 9.52562 1.73712 8.74342C1.11541 7.96122 0.804549 7.0611 0.804549 6.04306V5.99892C1.34135 6.29406 1.9557 6.45634 2.6476 6.48576C2.09694 6.1019 1.65914 5.5965 1.3342 4.96957C1.00926 4.34263 0.846791 3.66748 0.846791 2.94412C0.846791 2.20628 1.02334 1.48315 1.37644 0.774733C2.40758 2.11779 3.65405 3.17657 5.11584 3.95107C6.57763 4.72558 8.16312 5.15719 9.8723 5.24592C9.77352 4.877 9.72413 4.55244 9.72413 4.27224C9.72413 3.09169 10.1266 2.08452 10.9316 1.25071C11.7366 0.416903 12.7183 0 13.8768 0C14.4418 0 14.9855 0.118145 15.508 0.354435C16.0305 0.590726 16.4755 0.922754 16.8429 1.35052C17.7752 1.15859 18.6439 0.811852 19.4489 0.310301C19.1521 1.31386 18.5519 2.1033 17.6481 2.67864C18.5098 2.56049 19.2938 2.33914 20 2.01458V2.01254Z"
        fill={fillColor}
      />
    </svg>
  )

  return (
    <div
      id="p-team"
      className={`p-team relative bg-${theme}`}
      data-scroll-section
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="team"
      />

      <img
        data-scroll
        data-scroll-repeat
        data-scroll-speed="3"
        data-scroll-call="scene-team"
        attr-scene="team"
        className="absolute top-4 -left-[3%] z-20 w-[17%] dark:w-[12%]"
        src={`/sky-${theme}-1.webp`}
        alt=""
      />
      <h2
        data-scroll
        data-scroll-speed="1.1"
        className="
            text-title
            z-10
            pt-16
            pb-0
            text-center
            md:pt-16"
        style={{ color: fillColor }}
      >
        Meet The Team
      </h2>
      <div
        className="team-grid z-10 mx-auto grid max-w-screen-7xl grid-cols-2 gap-x-4 px-4 md:grid-cols-4 md:gap-x-5 md:px-14"
        data-scroll
        data-scroll-speed="1.1"
      >
        {team.map((e, i) => (
          <a
            href={e.twitter !== "" ? `https://twitter.com/${e.twitter}` : ""}
            target="_blank"
            key={i}
            className="team-member block w-full rounded-xl"
          >
            <div className="team-member-contents relative h-full w-full">
              <div
                className="mb-3 w-full rounded-xl pt-[117%]"
                style={{
                  backgroundImage: `url(/team/${e.image}.webp)`,
                }}
              />
              <h3
                className="text-subhead items-center pt-2 leading-5"
                style={{ color: fillColor }}
              >
                <span>{e.handle}</span>
                <br />
                <span className="font-sans text-sm opacity-60">{e.role}</span>
                {e.twitter !== "" && (
                  <>
                    <span className="px-1 font-sans text-sm opacity-60">/</span>
                    <span className="font-sans text-sm underline opacity-60">
                      @{e.twitter}
                    </span>
                  </>
                )}
              </h3>
              <p className="text-p pt-2 pb-16 opacity-80">
                {e.desc}
                <br />
              </p>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default HomeTeam
