import { Disclosure, Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { useTheme } from "next-themes"
import VideoBlob from "../components/VideoBlob"

const HomeFaqs = (props) => {
  const [fillColor, setFillColor] = useState("#1D1A25")
  const { theme } = useTheme()
  const [oppositeTheme, setOppositeTheme] = useState("dark")

  useEffect(() => {
    setFillColor(theme === "light" ? "#1D1A25" : "#fff")
    setOppositeTheme(theme === "dark" ? "light" : "dark")
  }, [theme])

  const faqs = [
    {
      q: "When is the mint?",
      a: `Mint date is tbd. We will announce the mint date on this website and <a href="https://twitter.com/MagicFolk_NFT" target="_blank">Twitter</a>. Please turn on notifications to receive updates.`,
    },
    {
      q: "What is the mint price?",
      a: `Mint price is tbd. We will announce the mint date on this website and <a href="https://twitter.com/MagicFolk_NFT" target="_blank">Twitter</a>. Please turn on notifications to receive updates.`,
    },
    {
      q: "How to Allowlist?",
      a: `The How-To-Allowlist info is available in our Discord. Please visit channel <a href="https://discord.com/channels/954989045472178267/958957319302045746" target="_blank">#how-to-wl</a>. Our team is closely watching all interactions. Spread the word and do good and you might be blessed. For our Partners and Collabs, we are preparing a hassle free Allowlist solution that should be live soon.`,
    },
    {
      q: "Who is the artist?",
      a: `Our amazing artist is Pexx.<br />
          <a href="https://www.pexxstudio.com" target="_blank">https://www.pexxstudio.com</a><br />
          <a href="https://twitter.com/Pexx41194765" target="_blank">https://twitter.com/Pexx41194765</a><br />
          <a href="https://opensea.io/collection/thepickled" target="_blank">https://opensea.io/collection/thepickled </a>
          `,
    },
    {
      q: "The NFTs will be customizable?",
      a: "Magic Folk is the first customizable NFT. You can customize your NFT after mint with our ERC1155 accessory NFTs. Accessories are pets, magic items, weapons or more. Accessories are added onto your existing NFT and they can also be traded, meaning, removed from your NFT at a later point. Watch out for Easter eggs and limited-edition accessories. Accessories will be available through the shop on our website.",
    },
    {
      q: "How does the customization work?",
      a: "When staking your NFT, rarities are a multiplicator and you earn $GEMZ for staking it. $GEMZ is an ERC-20 token. It is burnt, when you claim your equipment item. Equipment items are ERC1155 NFTs.",
    },
    {
      q: "Can the $GEMZ token be traded?",
      a: `<p className="pb-6">No. $GEMZ are earned holding your NFT and can be spent in our shop. The token itself cannot be traded at first.</p><p>In the Future, we want to extend the integration possibility of our tokenomics. Our ERC20 token has a switch that can enable trading. When the time is right, we can enable $GEMZ trading.</p>`,
    },
    {
      q: "How do I get invited to Discord?",
      a: "Currently access to our Discord is only open for selected individuals or groups. Our team is watching all interactions on Twitter closely. Spread the words and engage and you might be blessed.",
    },
    {
      q: "Collaborations and partnerships",
      a: "Magic folk is partnering with like minded projects to offer exclusive benefits to both of our communities. Partnerships are handpicked by our management team and announced on our website, on Twitter and in our Discord.",
    },
  ]

  return (
    <div
      id="scene-faqs"
      className={`p-faqs relative z-10 w-full bg-${theme}`}
      data-scroll-section
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="faqs"
      />

      <img
            data-scroll
            data-scroll-speed="-3"
            className="absolute top-[800px] left-0 z-10 w-[16%] dark:w-[8%] dark:left-[4%]"
            src={`/sky-${theme}-2.webp`}
            alt=""
          />

      <div
        className={`p-faqs mx-auto max-w-screen-7xl grid-cols-[40%_60%] px-4 py-16 md:grid md:px-20 md:pb-32`}
        data-scroll
      >
        <div className="relative h-full">
          <h2
            className="text-title pb-16 md:pt-28"
            style={{ color: fillColor }}
          >
            Your questions: <br />
            Granted.
          </h2>
          {/* <img src="/beast.webp" 
            className="absolute left-6 bottom-0 vpt:w-[45vw] vld:w-[60vh]"
            // style={{ transform: "translateY(-100%)" }}
          /> */}
          <VideoBlob
            autoPlay={true}
            videoSrc={`/faq-beast-${theme}`}
            oppositeVideoSrc={`/faq-beast-${oppositeTheme}`}
            classNames="relative -left-12 top-10 vpt:w-[45vw] vld:w-[60vh]"
          />
        </div>

        <div className="faq-list relative text-left">
          <div className="w-full rounded-3xl bg-[rgba(0,0,0,0.03)] shadow-xl dark:bg-[rgba(255,255,255,0.03)] md:p-16">
            {faqs.map((e, i) => (
              <Disclosure key={i}>
                <Disclosure.Button className="text-subhead w-full px-4 py-10 text-left">
                  {e.q}
                </Disclosure.Button>
                <Transition
                  enter="transition duration-200 ease-out"
                  enterFrom="transform scale-50 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-200 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="text-summary px-4 pb-16">
                    <div dangerouslySetInnerHTML={{ __html: e.a }} />
                  </Disclosure.Panel>
                </Transition>
                <div className="border-y-[1px] border-t-0 border-gray-400 opacity-20" />
              </Disclosure>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeFaqs
