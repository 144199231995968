import { useTheme } from "next-themes"

const chars = {
  light: [
    '/who-light-11.webp',
    '/who-light-1.webp',
    '/who-light-9.webp',
    '/who-light-3.webp',
    '/who-light-10.webp',
    '/who-light-5.webp',
  ],
  dark: [
    '/who-dark-8.webp',
    '/who-dark-1.webp',
    '/who-dark-2.webp',
    '/who-dark-7.webp',
    '/who-dark-4.webp',
    '/who-dark-5.webp',
  ]
}

const exampleColors = {
  wizards: ["909782", "BBA2C6", "C59878", "9BC1C9", "8CB6E9"],
  warlocks: ["C3B3A8", "BBA2C6", "C3B3A8", "BBA2C6", "333333"],
}

const HomeGallery = () => {
  const { theme } = useTheme()


  return (
    <div className="relative z-20 w-full" data-scroll-section>
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="gallery"
      />

      <div className={`bg-${theme} overflow-hidden py-6 md:py-20`} data-scroll>
        <div className="Carousel mb-4 md:mb-6">
          {[...Array(10).keys()].map((i) => (
            <div className="Block w-[34vw] px-2 md:w-[22.5vw] md:px-3" key={i}>
              <div
                className="relative w-full overflow-hidden rounded-3xl pt-[132%]"
                style={{
                  backgroundColor: `#${exampleColors.wizards[i % 5]}`,
                  backgroundImage: `url(${chars['light'][i % 5]})`,
                  backgroundSize: "140% auto",
                  backgroundPosition: "center bottom",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          ))}
        </div>

        <div className="Carousel reverse">
          {[...Array(10).keys()].map((i) => (
            <div className="Block w-[34vw] px-2 md:w-[22.5vw] md:px-3" key={i}>
              <div
                className="relative w-full overflow-hidden rounded-3xl pt-[132%]"
                style={{
                  backgroundColor: `#${exampleColors.warlocks[i % 5]}`,
                  backgroundImage: `url(${chars['dark'][i % 5]})`,
                  backgroundSize: "140% auto",
                  backgroundPosition: "center bottom",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeGallery
