import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"

const ModalDialog = (props) => {
  const { isOpen, setIsOpen, title, content } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 mx-auto flex items-center justify-center  overflow-hidden">
            <div className="quest-modal-bg relative w-[88%] max-w-[1500px] overflow-y-auto rounded-2xl bg-[#F1EFEA] p-4 text-left font-sans text-black shadow-xl md:pl-12 vpt:h-[48%] vpt:max-h-[900px] vld:h-[88%] vld:max-h-[1000px]">
              <div
                className="absolute left-0 top-0 z-10 h-full w-full opacity-10 md:opacity-100"
                style={{
                  backgroundImage: "url(/qm-building--hover.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "50% auto",
                  backgroundPosition: "120% center",
                }}
              />
              <div className="relative z-20 h-full w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="opacity-96 flex transform flex-col text-sm transition-all">
                    <Dialog.Title as="h3" className="text-title pb-6 pt-10">
                      Building 🛠
                      <button
                        type="button"
                        className="
                          absolute 
                          top-3
                          right-3
                          w-6
                          focus:outline-none "
                        onClick={closeModal}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.28572 0L0 4.28572L7.71429 12L0 19.7143L4.28572 24L12 16.2857L19.7143 24L24 19.7143L16.2857 12L24 4.28572L19.7143 0L12 7.71429L4.28572 0Z"
                            fill="#1D1A25"
                          />
                        </svg>
                      </button>
                    </Dialog.Title>
                    <div className="relative w-full overflow-y-auto">
                      <div className="md:w-[55%]">
                        <p className="text-summary font-normal">
                          Together, we are creating a solid foundation for an
                          extendible ecosystem of Web3 technologies.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Burn Mechanism
                          <span className="quest-progress bg-[#8FC34D]">
                            Complete
                          </span>
                          <span className="quest-progress bg-[#5E8927]">
                            Ongoing
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          There&rsquo;s no point in just endlessly earning
                          utility tokens. We have made sure to have items to
                          acquire, and have items from seasonal drops, tools
                          (subscription access), services, WL spots and more, to
                          help provide burn mechanisms for tokens.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Collab exclusive items and access to tools
                          <span className="quest-progress bg-[#74A6F0]">
                            In Progress
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We are interested in building real, long-term
                          partnerships with other like-minded communities. To
                          start with, we are creating a item that can be
                          equipped. During the second phase, we will give collab
                          level access to some our our tools.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Hackathons
                          <span className="quest-progress bg-[#F5A23B]">
                            Exploring
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We want to empower our community, and builders in the
                          space. One way of doing this could be as simple as a
                          Magic hackathon with prizes.
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
