import { useEffect, useState, useRef } from "react"
import { useInViewEffect } from "react-hook-inview"
import { useTheme } from "next-themes"
import lCheckmark from "../public/l-checkmark.json"
import lottie from "lottie-web"
import React from "react"

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const detailPoints = [
  "Magic Marketplace",
  "Capable Creators",
  "Realistic Strategy",
  "Community Storytelling",
  "Holder Exclusive Tools",
  "Exclusive Collab Items",
  "Gamified Tokenomics",
  "Extendible Ecosystem",
  "Coal",
]

let newCheckmarks = []

const HomeDetails = (props) => {
  const { theme } = useTheme()
  const lDetailsContainer = useRef()

  const checkmarksPlay = async (checkmarks) => {
    // console.log('checkmarksPlay')
    let delay = 300
    await asyncForEach(checkmarks, async (e) => {
      await sleep(delay)
      e.goToAndPlay(1, true)
    })
  }

  const checkmarksEnter = async () => {
    // console.log('checkmarksEnter')

    if (
      document.querySelector(".l-details-checkmark").childNodes.length === 0
    ) {
      // console.log('checkmarks - create')
      document
        .querySelectorAll(".l-details-checkmark")
        .forEach(async (e, index) => {
          newCheckmarks[index] = lottie.loadAnimation({
            container: e,
                        loop: false,
            autoplay: false,
            animationData: lCheckmark,
          })
        })
      checkmarksPlay(newCheckmarks)
    } else {
      checkmarksPlay(newCheckmarks)
    }
  }

  const ref = useInViewEffect(
    ([entry], observer) => {
      const lastY = observer.lastY || -1
      const newY = entry.boundingClientRect.y

      // once we leave, perform an action
      if (entry.isIntersecting !== true) {
        // if (newY < lastY) {
        //   // const pFactions = document.querySelector(
        //   //   ".faction.chosen .factions-mf-img"
        //   )
        //   if (pFactions === null) return
        //   // console.log("details - onLeave")
        //   // pFactions.classList.remove("slide-down", "magic-poof")
        // }
      } else {
        checkmarksEnter()
      }

      observer.lastY = newY
    },
    { threshold: 0.5 }
  )

  return (
    <>
      <div
        className="p-details z-60 relative bg-[#19171C]"
        data-scroll-section
        ref={ref}
      >
        <div
          className="absolute left-0 top-1 h-full w-1"
          data-scroll
          data-scroll-repeat
          data-scroll-call="sceneChange"
          attr-scene="details"
          id="scene-details"
        />
        <div data-scroll className="w-full py-16 md:py-48">
          <div
            ref={lDetailsContainer}
            id="s-details"
            className="mx-auto max-w-screen-3xl rounded-3xl px-4 text-[#FEFCF9] md:w-[90%] md:px-24"
          >
            <h2 className="text-title relative w-full font-display">
              Have a closer look...
            </h2>
            <p className="text-p relative w-full pt-0 italic">
              DYOR - The foundations of a great project 🪄
            </p>
            <div className="mx-auto grid-cols-3 items-center justify-center gap-12 pt-6 text-white md:grid md:pt-[6vw]">
              {detailPoints.map((point, i) => (
                <div
                  key={i}
                  className="mb-4 h-full 
                    w-full rounded-3xl
                  bg-[rgba(36,32,40,0.54)]
                    shadow-xl
                    md:mb-0
                    md:p-2"
                  style={{
                    backgroundImage: "url('/pattern-bg.webp')",
                    backgroundRepeat: "repeat",
                    backgroundSize: "50% auto",
                    borderColor: "rgba(254, 252, 249, 0.15)",
                    borderWidth: "1px",
                  }}
                >
                  <div className="relative flex items-center justify-start p-3">
                    <span className="l-details-checkmark relative left-0 mr-4 ml-2 h-9 w-9" />

                    <strong className="text-subhead font-display">
                      {point}
                    </strong>
                    {/* <p className="text-p opacity-70">
                    {e.desc}
                  </p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="z-60 relative h-screen w-full overflow-hidden"
        data-scroll-section
      >
        <div
          className="absolute left-0 top-[-50%] z-10 h-[200vh] w-full"
          data-scroll
          data-scroll-speed="2.2"
          style={{
            backgroundImage: `url(/details-${theme}-zoom.webp)`,
            backgroundSize: "cover",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            // transform: "translate3d(0,0,-2px) scale(3)",
          }}
        />
      </div>
    </>
  )
}

export default HomeDetails
