import { checkIfAppleDevice } from "../lib/frontend-helpers"
import { useEffect, useState, useRef } from "react"
import { useTheme } from "next-themes"
import HomeItemsMainHand from "./HomeItemsMainHand"
import HomeItemsOffHand from "./HomeItemsOffHand"
import HomePets from "./HomePets"
import lItemsSwapMe from "../public/l-items-swap-me.json"
import lottie from "lottie-web"
import VideoBlob from "./VideoBlob"

const HomeItems = (props) => {
  const [currentMainHand, setCurrentMainHand] = useState(1)
  const [currentOffHand, setCurrentOffHand] = useState(1)
  const [currentPet, setCurrentPet] = useState(1)
  const [hasSwapped, setHasSwapped] = useState(false)
  const [oppositeTheme, setOppositeTheme] = useState("dark")
  const { router, openOnload } = props
  const { theme } = useTheme()
  const mainHandRef = useRef()
  const offHandRef = useRef()
  const petRef = useRef()
  const SSR = typeof window === "undefined"

  useEffect(() => {
    if (SSR) return
    const isApple = checkIfAppleDevice(window)

    if (document.querySelector(".l-items-swap-me").childNodes.length > 0) return

    document.querySelectorAll(".l-items-swap-me").forEach(e => {
      const anim = lottie.loadAnimation({
        container: e,
                loop: true,
        autoplay: true,
        animationData: lItemsSwapMe,
      })

      if (isApple) {
        anim.goToAndStop(20, true)
      }
      // anim.setSpeed(1)
    })
  }, [SSR])

  useEffect(() => {
    if (!theme) return
    setOppositeTheme(theme === "dark" ? "light" : "dark")
  }, [theme])

  const togglePet = (e) => {
    petRef.current.querySelectorAll("div").forEach((item, index) => {
      if (index === currentPet) {
        item.classList.add("show")
        item.querySelector("video").play()
      } else {
        item.classList.remove("show")
        item.querySelector("video").pause()
      }
    })
    setCurrentPet((currentPet + 1) % 3)

    if (hasSwapped === false) setHasSwapped(true)
  }

  const toggleMainHand = (e) => {
    mainHandRef.current.querySelectorAll("div").forEach((item, index) => {
      if (index === currentMainHand) {
        item.classList.add("show")
      } else {
        item.classList.remove("show")
      }
    })
    setCurrentMainHand((currentMainHand + 1) % 3)

    if (hasSwapped === false) setHasSwapped(true)
  }

  const toggleOffHand = (e) => {
    offHandRef.current.querySelectorAll("div").forEach((item, index) => {
      if (index === currentOffHand) {
        item.classList.add("show")
      } else {
        item.classList.remove("show")
      }
    })
    setCurrentOffHand((currentOffHand + 1) % 3)

    if (hasSwapped === false) setHasSwapped(true)
  }

  useEffect(() => {
    if (hasSwapped === false) return

    document.querySelectorAll(".swap-me").forEach(e => e.classList.add('hidden'))
  }, [hasSwapped])
  

  return (
    <div
      className="p-accessories relative overflow-hidden py-16"
      data-scroll-section
    >
      <div className="relative pt-4 text-center md:pt-32">
        <h1 className="text-title text-white">Equip Your Items</h1>
      </div>
      <div className="mx:pt-32 grid gap-4 md:gap-8 px-4 py-16 md:px-32 md:pb-48 vpt:grid-cols-[30%_70%] md:vpt:grid-cols-[60%_40%] vld:grid-cols-[54%_46%]">
        <div data-scroll data-scroll-speed="-4" className="relative">
          <div
            className="
              absolute
              top-[20%]
              mx-0
              block
              pt-32
              md:top-1/2
              md:pl-24
              vpt:h-[120vw]
              vpt:w-[120vw]
              vpt:md:left-[44%]
              vld:h-[170vh]
              vld:max-h-[2000px]
              vld:w-[170vh]
              vld:max-w-[2000px]
              vld:md:left-[44%]"
            style={{ transform: "translate3d(-50%, -50%, 0)" }}
          >
            <div
              className="block h-screen w-auto md:h-full"
              style={{ transform: "scale(1) translate3d(0,0,0)" }}
            >
              <div className="relative left-16 h-full w-full md:left-0">
                <VideoBlob
                  classNames="h-full w-auto"
                  autoPlay={true}
                  videoSrc={`/accessories-${theme}`}
                  oppositeVideoSrc={`/accessories-${oppositeTheme}`}
                />

                <div
                  className="
                      off-hands
                      absolute
                      -right-[1%]
                      bottom-[18%]
                      w-[100%]
                      origin-top-right
                      pt-[100%]
                      dark:right-[1%]
                      dark:bottom-[16%]
                      md:-bottom-[1%]
                      md:dark:-bottom-[2%]"
                  ref={offHandRef}
                  onClick={toggleOffHand}
                  style={{
                    transformStyle: "preserve-3d",
                    willChange: "transform",
                  }}
                >
                  <div className="show absolute right-0 bottom-0 w-full">
                    <img src={`/items-offhand-${theme}-1.webp`} alt="" />
                  </div>
                  <div className="absolute right-0 bottom-0 w-full">
                    <img src={`/items-offhand-${theme}-2.webp`} alt="" />
                  </div>
                  <div className="absolute right-0 bottom-0 w-full">
                    <img src={`/items-offhand-${theme}-3.webp`} alt="" />
                  </div>
                  <span className="invisible md:visible swap-me absolute right-[27%] bottom-[24%] w-[19%]">
                    <span className="inline-block l-items-swap-me" />
                  </span>
                </div>

                <div
                  className="
                      pets
                      absolute
                      right-[12%]
                      top-[10%]
                      w-[29%]
                      md:top-[4%]"
                  ref={petRef}
                  onClick={togglePet}
                  style={{
                    transform: "translate3d(-20%, 0, 0)",
                    transformStyle: "preserve-3d",
                    willChange: "transform",
                  }}
                >
                  <div className="show absolute right-0 top-0 w-full">
                    <VideoBlob
                      classNames="w-full h-auto"
                      videoSrc={`/pets-${theme}-1`}
                      autoPlay={true}
                      oppositeVideoSrc={`/pets-${oppositeTheme}-1`}
                    />
                  </div>
                  <div className="absolute right-0 top-0 w-full">
                    <VideoBlob
                      classNames="w-full h-auto"
                      videoSrc={`/pets-${theme}-2`}
                      autoPlay={false}
                      oppositeVideoSrc={`/pets-${oppositeTheme}-2`}
                    />
                  </div>
                  <div className="absolute right-0 top-0 w-full">
                    <VideoBlob
                      classNames="w-full h-auto"
                      videoSrc={`/pets-${theme}-3`}
                      autoPlay={false}
                      oppositeVideoSrc={`/pets-${oppositeTheme}-3`}
                    />
                  </div>
                  <span className="invisible md:visible swap-me absolute right-[3vw] top-[1vh] w-[60%]">
                    <span className="inline-block l-items-swap-me" />
                    <img className="absolute right-0 top-[6vh] rotate-[4deg] w-[60%]" src="/items-swap-me.webp" alt="" />
                  </span>
                </div>

                <div
                  className="
                      main-hands
                      absolute
                      right-[44%]
                      bottom-[28%]
                      w-[34%]
                      origin-top-left
                      pt-[100%]
                      dark:right-[42%]
                      md:bottom-[17%]"
                  style={{
                    transformStyle: "preserve-3d",
                    willChange: "transform",
                  }}
                  ref={mainHandRef}
                  onClick={toggleMainHand}
                >
                  <div className="show absolute right-0 bottom-0 w-full">
                    <img src={`/items-mainhand-${theme}-1.webp`} alt="" />
                  </div>
                  <div className="absolute right-0 bottom-0 w-full">
                    <img src={`/items-mainhand-${theme}-2.webp`} alt="" />
                  </div>
                  <div className="absolute right-0 bottom-0 w-full">
                    <img src={`/items-mainhand-${theme}-3.webp`} alt="" />
                  </div>
                  <span className="invisible md:visible swap-me absolute right-[7%] bottom-[11%] w-[55%]">
                    <span className="inline-block l-items-swap-me" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pr-2 md:pl-[15%]">
          <HomePets
            router={router}
            openOnload={openOnload}
          />
          <HomeItemsMainHand
            openOnload={openOnload}
            router={router}
          />
          <HomeItemsOffHand
            openOnload={openOnload}
            router={router}
          />
        </div>
      </div>
    </div>
  )
}

export default HomeItems
