import React from "react"
import { useTheme } from "next-themes"
import { useEffect, useState } from "react"
import { checkIfFirefox } from "../lib/frontend-helpers"

const HomeEcosystem = (props) => {
  const { theme } = useTheme()
  const [oppositeTheme, setOppositeTheme] = useState("dark")
  const [isFF, setIsFF] = useState(false)
  const SSR = typeof window === "undefined"

  useEffect(() => {
    if (!theme) return
    setOppositeTheme(theme === "dark" ? "light" : "dark")
  }, [theme])

  useEffect(() => {
    if (SSR) return

    if (checkIfFirefox(window)) {
      setIsFF(true)
    }
  }, [])

  return (
    <div
      data-scroll-section
      className="p-ecosystem relative w-full bg-[#19171C] py-16 md:py-48"
      style={{
        backgroundImage: "url(/ecosystem-bg.webp)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        backgroundSize: "cover",
      }}
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="ecosystem"
        id="scene-ecosystem"
      />
      <div
        className="mx-auto flex h-full w-full max-w-screen-7xl justify-center text-left text-white md:items-center md:text-center"
        data-scroll
      >
        <div>
          <h2 className="text-title pb-6 pl-4 font-display">
            Growing the Marketplace
          </h2>
          <p
            className="
                  text-summary
                  relative 
                  mx-auto
                  px-4
                  pb-10
                  font-sans
                  opacity-80
                  md:w-1/2
                  md:px-0 
                  xl:my-10
                  xl:max-w-screen-xl"
          >
            The Magic Marketplace balances tokenomics with burn mechanics, variable pricing, and limited supply. The future tales of Magic Folk will be littered with strange and wonderful items, each with different abilities and integrations into new and unknown realms of Web3. Pets, Weapons and Magic Items will be available on mint!
          </p>
          <div className="ecosystem-card relative mx-auto grid-cols-4 justify-between gap-x-3 px-16 md:grid">
            <div className="card-outer relative">
              <div
                className={`card-back ${(isFF ? "hidden" : "")} absolute w-full rounded-2xl p-3 shadow-xl`}
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img
                  className="block w-full"
                  src={`/ecosystem1-${oppositeTheme}.webp`}
                  alt=""
                />
              </div>
              <div
                className="card-front relative w-full rounded-2xl p-3 shadow-xl"
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img
                  className="block w-full"
                  src={`/ecosystem1-${theme}.webp`}
                  alt=""
                />
              </div>
            </div>

            <div className="card-outer relative">
              <div
                className={`card-back ${(isFF ? "hidden" : "")} absolute w-full rounded-2xl p-3 shadow-xl`}
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img className="block w-full" src="/ecosystem2.webp" alt="" />
              </div>
              <div
                className="card-front relative w-full rounded-2xl p-3 shadow-xl"
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img className="block w-full" src="/ecosystem2.webp" alt="" />
              </div>
            </div>

            <div className="card-outer relative">
              <div
                className={`card-back ${(isFF ? "hidden" : "")} absolute w-full rounded-2xl p-3 shadow-xl`}
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img
                  className="block w-full"
                  src={`/ecosystem3-${oppositeTheme}.webp`}
                  alt=""
                />
              </div>
              <div
                className="card-front relative w-full rounded-2xl p-3 shadow-xl"
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img
                  className="block w-full"
                  src={`/ecosystem3-${theme}.webp`}
                  alt=""
                />
              </div>
            </div>

            <div className="card-outer relative">
              <div
                className={`card-back ${(isFF ? "hidden" : "")} absolute w-full rounded-2xl p-3 shadow-xl`}
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img className="block w-full" src="/ecosystem4.webp" alt="" />
              </div>
              <div
                className="card-front relative w-full rounded-2xl p-3 shadow-xl"
                style={{
                  borderColor: "rgba(254, 252, 249, 0.15)",
                  borderWidth: "1px",
                }}
              >
                <img className="block w-full" src="/ecosystem4.webp" alt="" />
              </div>
            </div>
          </div>
          {/* <img className="w-full px-4 md:px-16" src="/ecosystem.webp" alt="" /> */}
        </div>
      </div>
    </div>
  )
}

HomeEcosystem.propTypes = {}

export default HomeEcosystem
