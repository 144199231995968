import { LocomotiveScrollProvider } from "react-locomotive-scroll"
import { useRef, useEffect, useState } from "react"
import { useAppContext } from "../../context/state"
import { useRouter } from "next/router"
import { useTheme } from "next-themes"
import { checkIfIOS } from "../../lib/frontend-helpers"
import { SCENE_NAMES, TECH_MODAL_NAMES } from "../../lib/frontend-const"
import Head from "next/head"
import Header from "../../components/Header"
import HeaderSecondary from "../../components/HeaderSecondary"
import HomeAdvisors from "../../components/HomeAdvisors"
import HomeDetails from "../../components/HomeDetails"
import HomeEcosystem from "../../components/HomeEcosystem"
import HomeMarketplace from "../../components/HomeMarketplace"
import HomeFactions from "../../components/HomeFactions"
import HomeFaqs from "../../components/HomeFaqs"
import HomeFooter from "../../components/HomeFooter"
import HomeGallery from "../../components/HomeGallery"
import HomeIntro from "../../components/HomeIntro"
import HomeItems from "../../components/HomeItems"
import HomeOpen from "../../components/HomeOpen"
import HomeQuestMap from "../../components/HomeQuestMap"
import HomeScrollingBanner from "../../components/HomeScrollingBanner"
import HomeTeam from "../../components/HomeTeam"
import HomeWho from "../../components/HomeWho"
import lLoader from "../../public/l-loading.json"
import lottie from "lottie-web"
import Script from "next/script"

const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export default function HomepageParallax() {
  const globalState = useAppContext()
  const router = useRouter()

  const [audioCached, setAudioCached] = useState(false)
  const [factionEntered, setFactionEntered] = useState(false)
  const [imagesCached, setImagesCached] = useState(false)
  const [isCached, setIsCached] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const [openOnload, setOpenOnload] = useState(false)
  const [videoCached, setVideoCached] = useState(false)
  const [skippedFactions, setSkippedFactions] = useState(false)
  const { setFactionChosen } = globalState
  const { theme, setTheme } = useTheme()
  const containerRef = useRef()
  const loaderRef = useRef()

  // weird issues with SSR / hot-reloading
  if (theme === "system") setTheme("light")
  const SSR = typeof window === "undefined"

  useEffect(() => {
    if (SSR) return
    setFactionEntered(true)
    setIsIOS(checkIfIOS(window))
  }, [])

  useEffect(() => {
    // console.log(`  - videos loaded = ${globalState.videoLoadedCount}`)
    if (globalState.videoLoadedCount >= 14) {
      // console.log('- video loaded')
      setVideoCached(true)
    }
  }, [globalState.videoLoadedCount])

  useEffect(() => {
    if (globalState.audioLoadedCount >= 2) {
      // console.log('- audio loaded')
      setAudioCached(true)
    }
  }, [globalState.audioLoadedCount])

  useEffect(() => {
    // note that we can't judge audio on iOS as they don't allow autoplay
    if (videoCached && imagesCached && (audioCached || isIOS)) {
      setIsCached(true)
    }
  }, [videoCached, imagesCached, audioCached])

  useEffect(() => {
    if (!isCached) return

    // console.log(`- updating localStorage isCached to ${isCached}`)
    localStorage.setItem('isCached', `${isCached}`);
  }, [isCached])

  useEffect(() => {
    if (localStorage.getItem('isCached') === 'true') setIsCached(true)

    const loadInCaseCacheFailed = async () => {
      await sleep(25000)
      if (!isCached) {
        // console.log('- caching failed, showing what is there')
        setIsCached(true)
      }
    }
    loadInCaseCacheFailed()
  }, [])

  const cacheImages = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })

    await Promise.all([...promises, sleep(3000)])

    // console.log('- images loaded')
    setImagesCached(true)
  }

  useEffect(() => {
    const themeVariants = ["dark", "light"]
    if (isCached === true) return

    const imgs = themeVariants.reduce((acc, t) => {
      const tmplImgs = [
        '/pattern-bg.webp',
        // who
        ...[...Array(10).keys()].map((i) => `/who-light-${i}.webp`),
        ...[...Array(8).keys()].map((i) => `/who-dark-${i}.webp`),
        // details
        `/details-${t}-zoom.webp`,
        // items
        `/pets-${t}.webp`,
      ]

      return [...acc, ...tmplImgs]
    }, [])

    cacheImages(imgs)
  }, [])

  const [ogUrl, setOgUrl] = useState("")
  useEffect(() => {
    if (SSR) return
    const host = window.location.host
    const baseUrl = `https://${host}`

    setOgUrl(`${baseUrl}`)
  }, [router.pathname])

  useEffect(() => {
    if (loaderRef.current) {
      const anim = lottie.loadAnimation({
        container: loaderRef.current,
        loop: true,
        autoplay: true,
        animationData: lLoader,
        preserveAspectRatio:'xMidYMid meet',
      })

      return () => anim?.destroy()
    }
  }, [])

  return (
    <>
      <Head>
        <title>Magic Folk</title>

        <meta name="description" content="Bringing Magic to Web3" />
        <link rel="canonical" href="https://www.magicfolk.io/" />
        <meta property="og:locale" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Magic Folk" />
        <meta property="og:description" content="Bringing Magic to Web3" />
        <meta property="og:url" content="" />
        <meta property="og:site_name" content="Magic Folk" />
        <meta property="og:image" content={`${ogUrl}/banner.jpg`} />
        <meta property="og:image:secure_url" content={`${ogUrl}/banner.jpg`} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Bringing Magic to Web3" />
        <meta name="twitter:title" content="Magic Folk" />
        <meta name="twitter:site" content="@MagicFolk_NFT" />
        <meta
          name="twitter:image"
          content="https://www.magicfolk.io/banner.jpg"
        />
        <meta name="twitter:creator" content="@MagicFolk_NFT" />

        {/* https://css-tricks.com/the-notch-and-css/ */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />

        <link rel="apple-touch-icon" href="favicon.png" />

        <meta
          name="facebook-domain-verification"
          content="8jc7mzrbelo2ln1binmnh1476y9can"
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '697516894735738');
          fbq('track', 'PageView');`,
          }}
        />
      </Head>
      {(!isCached) && (
        <div
          style={{
            backgroundColor: '#242229',
            backgroundImage: `url('/pattern-bg.webp')`,
            backgroundRepeat: 'repeat',
            backgroundSize: '565px auto',
            position: 'absolute',
            zIndex: '9001',
            width: '100vw',
            height: '100vh',
            top: '0',
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div className="w-[10vw] min-w-[150px]" ref={loaderRef} />
        </div>
      )}

      {(theme) && (
        <>
        <div className={`screen-bg relative h-screen w-screen ${(isCached) ? "visible" : "invisible"}`}>
          <LocomotiveScrollProvider
            options={{
              smooth: true,
              tablet: { smooth: true },
              smartphone: { smooth: true },
              getDirection: true,
              reloadOnContextChange: true,
            }}
            watch={[isCached]}
            containerRef={containerRef}
            onUpdate={(e) => {
              if (router.query?.hscene !== undefined) {
                const uri = router.query?.hscene
                if (uri.length === 1 && SCENE_NAMES.includes(uri[0])) {
                  setFactionChosen(true)
                  setSkippedFactions(true)
                  e.scrollTo(`#scene-${uri[0]}`)
                } else if (
                  uri.length === 2 &&
                  Object.keys(TECH_MODAL_NAMES).includes(uri[1])
                ) {
                  setFactionChosen(true)
                  setSkippedFactions(true)
                  e.scrollTo(`#scene-${TECH_MODAL_NAMES[uri[1]]}`)
                  // triggers modal in the scene we scrolled to
                  setOpenOnload(true)
                }
              }
            }}
          >
            <div
              data-scroll-container
              ref={containerRef}
              className="scroll-container relative z-50"
            >
              <HomeIntro />
              <HomeWho />
              <HomeDetails />
              <HomeMarketplace router={router} openOnload={openOnload} />
              <HomeItems router={router} openOnload={openOnload} />
              <HomeOpen router={router} openOnload={openOnload} />
              <HomeEcosystem />
              <HomeQuestMap router={router} />
              <HomeScrollingBanner phrase="Bringing Magic to Web3" />
              <HomeAdvisors />
              <HomeTeam />
              <HomeFaqs />
              <HomeGallery />
              <HomeScrollingBanner phrase="Bringing Magic to Web3" />
              <HomeFooter />
            </div>
            <Header
              router={router}
              containerRef={containerRef}
              skippedFactions={skippedFactions}
            />
            <HeaderSecondary />
          </LocomotiveScrollProvider>
          <HomeFactions factionEntered={factionEntered} />
        </div>
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DZQ7ZEDLQH"
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-DZQ7ZEDLQH');`,
          }}
        />
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-224687674-1"
        />
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-224687674-1');`,
          }}
        />
      </>
      )}
    </>
  )
}
