import { useLocomotiveScroll } from "react-locomotive-scroll"
import { useRef, useEffect } from "react"
import { useTheme } from "next-themes"
import lDarkModeToggleData from "../public/l-dark-mode-toggle.json"
import lottie from "lottie-web"
import React from "react"

let isStatic = true
let isHidden = false

const HeaderSecondary = (props) => {
  const { theme, setTheme } = useTheme()
  const lThemeToggle = useRef(null)
  const lThemeToggleContainer = useRef(null)

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light")
    lThemeToggle.current?.setDirection(theme === "dark" ? -1 : 1)
    lThemeToggle.current?.play()
  }

  useEffect(() => {
    lThemeToggle.current?.setDirection(theme === "dark" ? 1 : -1)
    lThemeToggle.current?.play()
  }, [theme])

  useEffect(() => {
    if (lThemeToggleContainer.current) {
      lThemeToggle.current = lottie.loadAnimation({
        container: lThemeToggleContainer.current,
                loop: false,
        autoplay: false,
        animationData: lDarkModeToggleData,
      })

      if (theme === "dark") {
        lThemeToggle.current?.goToAndStop(23, true)
      }

      return () => lThemeToggle.current?.destroy()
    }
  }, [])

  const { scroll } = useLocomotiveScroll()
  const headerRef = useRef()

  useEffect(() => {
    if (scroll === null) return

    scroll.on("scroll", (instance) => {
      const header = headerRef?.current
      if (!header) return

      let headerHeight = header.getBoundingClientRect().height
      if (instance.direction === "down" && isStatic) {
        if (instance.scroll.y > headerHeight) {
          // console.log('header - pinned')
          header.classList.add("pinned")
          isStatic = false
        }
      }
      if (instance.direction === "up" && !isStatic) {
        if (instance.scroll.y <= headerHeight) {
          // console.log('header - unpinned')
          header.classList.remove("pinned")
          isStatic = true
        }
      }
      if (instance.direction === "down" && isHidden) {
        if (instance.scroll.y > headerHeight + 200) {
          // console.log('header - hidden')
          header.classList.remove("unpinned")
          header.classList.add("pinned")
          isHidden = false
        }
      }
      if (instance.direction === "up" && !isHidden) {
        // console.log('header - show')
        header.classList.remove("pinned")
        header.classList.add("unpinned")
        isHidden = true
      }
    })
  }, [scroll])

  return (
    <header
      className="header-secondary unpinned fixed left-0 top-0 z-[51] w-full py-[1.6vmin] px-10"
      ref={headerRef}
    >
      <nav className="grid max-w-[2100px] grid-cols-3 items-center self-center py-2 3xl:mx-auto">
        <div className="flex w-full items-center justify-start"></div>

        <div className="no-select mx-auto max-w-[100px] cursor-pointer drop-shadow-lg">
          <div onClick={() => toggleTheme()} ref={lThemeToggleContainer} />
        </div>

        <ul className="flex items-center justify-end"></ul>
      </nav>
    </header>
  )
}

HeaderSecondary.propTypes = {}

export default HeaderSecondary
