import { useEffect, useState } from "react"
import { useInViewEffect } from "react-hook-inview"
import { useTheme } from "next-themes"
import ModalTechNFT from "../components/ModalTechNFT"
import React from "react"

const HomeOpen = (props) => {
  const { router, openOnload } = props
  const [isOpen, setIsOpen] = useState(false)
  const [hasOpened, setHasOpened] = useState(false)
  const { theme } = useTheme()

  useEffect(() => {
    if (isOpen === false && hasOpened === true) {
      setHasOpened(false)
      return
    }

    // modal opens, update uri
    if (isOpen === true && hasOpened === false) {
      if (isOpen === true) setHasOpened(true)
      document.querySelector(".nav-header").classList.remove("pinned")

      const route = "/home/technical/nft"
      router.push(route, undefined, { shallow: true })
    }
  }, [isOpen])

  useEffect(() => {
    if (openOnload === false) return
    const uri = router.query?.hscene
    if (uri === undefined || uri.length !== 2 || uri[1] !== "nft") return

    setIsOpen(true)
  }, [openOnload, router.query])

  const ref = useInViewEffect(
    ([entry], observer) => {
      const lastY = observer.lastY || -1
      const newY = entry.boundingClientRect.y

      if (entry.isIntersecting === true) {
        const pFactions = document.querySelector(".photo-items-start")
        // console.log("s-open - onEnter")
        pFactions.classList.add("trigger-photo")
      }

      observer.lastY = newY
    },
    { threshold: 0.5 }
  )

  return (
    <div
      className="p-opensea photo-items-start relative"
      data-scroll-section
      ref={ref}
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="open"
        id="scene-open"
      />
      <div
        className="w-full py-16 md:py-48"
        data-scroll
        style={{
          // transform: aOpenseaEntrance,
          backgroundColor: "rgb(34,31,39)",
          backgroundImage: "url(/open-bg.webp",
          backgroundSize: "100% auto",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
      >
        <div className="relative mx-auto w-[90%] max-w-screen-7xl grid-cols-2 md:grid">
          <div className="flex items-start justify-center">
            <img
              src={`/open-${theme}-snapshot.webp`}
              alt=""
              className="w-96 pb-6 md:pb-0"
            />
          </div>
          <div className="flex flex-col items-start justify-center text-white">
            <h6 className="tech-label mb-10">Upgradeable&nbsp;&nbsp;NFTs</h6>
            <h2
              className="
              text-title
              pb-4
              "
            >
              Show Off Your Items
            </h2>
            <h3 className="text-subhead pb-6">For everyone to see.</h3>

            <div
              className="
              text-summary
              relative
              opacity-80"
            >
              <div className="md:pb-20">
                <p className="pb-6">
                  Any item you add to your Magic Folk will update your metadata,
                  and change your NFT on all your favorite Web3 platforms:
                  OpenSea, LooksRare, MetaMask, etc.
                </p>

                <p className="">
                  The Magic Folk world is open for all. Functionality is exposed
                  through low level smart contract access.
                </p>
                {/*               
              <p className="pb-6">The Magic Council DAO will help determine the direction of development.</p>
                <p className="pb-6">The Magic Folks vision of growing our Web3 brand and ecosystem includes a focus on interoperability.</p>
                <p className="pb-6">That sounds great, but what does it actually mean? </p>
                <p className="pb-6">It means plug and play integration with things like:
                <br />• Metaverses 
                <br />• Games (like galaxy fight club)
                <br />• Tools / Services for the community</p>
                <p className="pb-6">The plug and play use cases are pretty scarce at the moment. That&rsquo;s why we chose not to include it on our roadmap 1.0. However, it is something the team would love to work on in future.</p>
                <p>In phase 2 when we open up the Magic Council DAO, we&rsquo;ll allow the community to have a say which interoperability we focus on.</p> */}
              </div>
            </div>
            <ModalTechNFT isOpen={isOpen} setIsOpen={setIsOpen} />
            <h6
              onClick={() => setIsOpen(true)}
              className="tech-label mb-6 cursor-pointer rounded-xl py-2 pr-4 pl-3 font-bold opacity-70 hover:opacity-100"
              style={{
                transform: "scale(0.8) translate3d(-12%,0,0)",
                transition: "opacity 350ms",
              }}
            >
              <svg
                className="relative -top-[2px] inline-block h-6 w-8"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.5126 10.6574L22.1253 9.93899C21.8989 9.08778 21.5593 8.27101 21.1163 7.50344L22.2927 5.30899C22.4354 5.03838 22.3862 4.7038 22.1696 4.48731L20.5059 2.82425C20.2894 2.60775 19.9546 2.55855 19.6839 2.70124L17.4985 3.88703C16.7306 3.4442 15.9184 3.1047 15.062 2.87837L14.3433 0.492029C14.2547 0.196812 13.984 0 13.6788 0H11.326C11.0208 0 10.7501 0.201732 10.6615 0.492029L9.94287 2.87837C9.0864 3.1047 8.27424 3.4442 7.50637 3.88703L5.31107 2.71108C5.04034 2.56839 4.71056 2.61267 4.49398 2.83409L2.83027 4.49223C2.61369 4.70872 2.56447 5.0433 2.70722 5.31391L3.88855 7.50344C3.44555 8.27101 3.10592 9.08286 2.87949 9.93899L0.492221 10.6574C0.196888 10.7459 0 11.0165 0 11.3216V13.6735C0 13.9785 0.201811 14.2492 0.492221 14.3377L2.87949 15.0561C3.10592 15.9073 3.44555 16.7241 3.88855 17.4916L2.71214 19.6861C2.56939 19.9567 2.61862 20.2913 2.83519 20.5078L4.4989 22.1708C4.71548 22.3873 5.05019 22.4365 5.31599 22.2938L7.51129 21.1179C8.27916 21.5607 9.09132 21.9002 9.94779 22.1265L10.6664 24.508C10.755 24.8032 11.0258 25 11.3309 25H13.6837C13.9889 25 14.2596 24.7983 14.3482 24.508L15.0669 22.1265C15.9184 21.9002 16.7355 21.5607 17.5034 21.1179L19.6987 22.2938C19.9694 22.4365 20.3041 22.3873 20.5207 22.1708L22.1844 20.5078C22.401 20.2913 22.4502 19.9567 22.3075 19.6861L21.1163 17.4916C21.5593 16.729 21.8989 15.9122 22.1253 15.0561L24.5077 14.3377C24.803 14.2492 24.9999 13.9785 24.9999 13.6735V11.3216C25.0048 11.0165 24.803 10.7459 24.5126 10.6574ZM18.9655 12.5C18.9655 16.0708 16.0708 18.9655 12.5 18.9655C8.92919 18.9655 6.03448 16.0708 6.03448 12.5C6.03448 8.92919 8.92919 6.03448 12.5 6.03448C16.0708 6.03448 18.9655 8.92919 18.9655 12.5Z"
                  fill="#FEFCF9"
                />
              </svg>
              <div className="inline-block pl-2 text-[1.1rem]">
                NFTs - Read Specs
              </div>
            </h6>
          </div>
        </div>

        <div
          className="photo-layer absolute left-0 top-0 hidden h-full w-full bg-white opacity-0 md:block"
          data-scroll
        />
      </div>
    </div>
  )
}

export default HomeOpen
