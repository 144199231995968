import React from "react"
import { useEffect, useState } from "react"
import Marquee from "react-fast-marquee"

const HomeScrollingBanner = (props) => {
  const { phrase } = props

  return (
    <div
      className={`relative w-full overflow-hidden bg-black py-48 pb-10`}
      data-scroll-section
    >
      <div
        data-scroll
        data-scroll-speed="1.7"
        className="absolute top-[-78%] left-0 z-10 h-[400%] w-full opacity-50"
        style={{
          backgroundImage: "url('/banner-bg2.webp')",
          backgroundSize: "100% auto",
        }}
      />
      <div
        className="scrolling-banner absolute left-0 top-0 h-full w-full z-20 flex flex-row overflow-hidden items-center py-16 pl-4 font-sans text-[2em] font-bold uppercase"
        data-scroll
      >
        <Marquee
          gradient={false}
          pauseOnHover={true}
        >
          <div className="marquee py-16">
            <span>
              {[...Array(12).keys()].map((i) => {
                let innerC = (
                  <div key={i} className="inline-block">
                    <h2 className={`inline-block text-white`}>
                      <div>{phrase}</div>
                    </h2>
                    <svg
                      className="relative -top-[4px] mx-4 inline-block w-8"
                      viewBox="0 0 31 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.0941 1.78104C16.0024 1.53215 15.7652 1.36682 15.5 1.36682C15.2348 1.36682 14.9976 1.53215 14.9059 1.78104L11.5068 11.0038L1.68502 11.3866C1.41998 11.3969 1.18946 11.5713 1.10749 11.8236C1.02553 12.0759 1.10949 12.3525 1.31784 12.5166L9.03883 18.5994L6.36776 28.0587C6.29568 28.3139 6.39035 28.5871 6.60494 28.743C6.81953 28.8989 7.10856 28.9045 7.32906 28.7571L15.5 23.2937L23.6709 28.7571C23.8914 28.9045 24.1805 28.8989 24.3951 28.743C24.6096 28.5871 24.7043 28.3139 24.6322 28.0587L21.9612 18.5994L29.6822 12.5166C29.8905 12.3525 29.9745 12.0759 29.8925 11.8236C29.8105 11.5713 29.58 11.3969 29.315 11.3866L19.4932 11.0038L16.0941 1.78104Z"
                        fill="#fff"
                        stroke="#fff"
                        strokeWidth="1.26635"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                )
                return innerC
              })}
            </span>
          </div>
        </Marquee>
      </div>
    </div>
  )
}

export default HomeScrollingBanner
