import { useEffect, useState } from "react"
import ModalQuest1 from "./ModalQuest1"
import ModalQuest2 from "./ModalQuest2"
import ModalQuest3 from "./ModalQuest3"
import ModalQuest4 from "./ModalQuest4"
import ModalQuest5 from "./ModalQuest5"

const HomeQuestMap = (props) => {
  const { router } = props

  const [q1Open, setQ1Open] = useState(false)
  const [hasQ1Opened, setHasQ1Opened] = useState(false)
  useEffect(() => {
    if (q1Open === true) setHasQ1Opened(true)
    if (hasQ1Opened === false && q1Open === false) return
    let route = "/home/questmap"
    if (q1Open === true) route = "/home/questmap/global-branding"
    document.querySelector(".nav-header").classList.remove("pinned")
    router.push(route, undefined, { shallow: true })
  }, [q1Open])

  const [q2Open, setQ2Open] = useState(false)
  const [hasQ2Opened, setHasQ2Opened] = useState(false)
  useEffect(() => {
    if (q2Open === true) setHasQ2Opened(true)
    if (hasQ2Opened === false && q2Open === false) return
    let route = "/home/questmap"
    if (q2Open === true) route = "/home/questmap/metaverse"
    document.querySelector(".nav-header").classList.remove("pinned")
    router.push(route, undefined, { shallow: true })
  }, [q2Open])

  const [q3Open, setQ3Open] = useState(false)
  const [hasQ3Opened, setHasQ3Opened] = useState(false)
  useEffect(() => {
    if (q3Open === true) setHasQ3Opened(true)
    if (hasQ3Opened === false && q3Open === false) return
    let route = "/home/questmap"
    if (q3Open === true) route = "/home/questmap/pushing-boundaries"
    document.querySelector(".nav-header").classList.remove("pinned")
    router.push(route, undefined, { shallow: true })
  }, [q3Open])

  const [q4Open, setQ4Open] = useState(false)
  const [hasQ4Opened, setHasQ4Opened] = useState(false)
  useEffect(() => {
    if (q4Open === true) setHasQ4Opened(true)
    if (hasQ4Opened === false && q4Open === false) return
    let route = "/home/questmap"
    if (q4Open === true) route = "/home/questmap/vision-goals"
    document.querySelector(".nav-header").classList.remove("pinned")
    router.push(route, undefined, { shallow: true })
  }, [q4Open])

  const [q5Open, setQ5Open] = useState(false)
  const [hasQ5Opened, setHasQ5Opened] = useState(false)
  useEffect(() => {
    if (q5Open === true) setHasQ5Opened(true)
    if (hasQ5Opened === false && q5Open === false) return
    let route = "/home/questmap"
    if (q5Open === true) route = "/home/questmap/building"
    document.querySelector(".nav-header").classList.remove("pinned")
    router.push(route, undefined, { shallow: true })
  }, [q5Open])

  return (
    <div
      id="p-questmap"
      data-scroll-section
      className={`p-quest bg-dark relative w-full py-16 text-center md:py-32`}
    >
      <div
        className="absolute left-0 top-1 h-full w-1"
        data-scroll
        data-scroll-repeat
        data-scroll-call="sceneChange"
        attr-scene="quest-map"
        id="scene-quest-map"
      />
      <h6 className="tech-label relative mx-auto mb-10 inline-block">
        Road Map
      </h6>
      <h2 className="text-title pb-10 font-display text-white">Quest Map</h2>

      <div className="quest-container relative mx-auto w-[90%] max-w-screen-7xl overflow-hidden rounded-md border-[1.25px] border-[#454248] bg-[#221F27] px-[14%] py-12">
        <div className="absolute left-0 top-0 z-10 h-full w-[2040px]">
          <div className="qm-carousel absolute z-10 h-full">
            {[...Array(10).keys()].map((i) => (
              <div className="qm-carousel--block h-full md:w-[408px]" key={i}>
                <div
                  className="relative h-full w-full"
                  style={{
                    backgroundImage: "url('/qm-bg-1.webp')",
                    backgroundSize: "auto 70px",
                    backgroundRepeat: "repeat",
                  }}
                />
              </div>
            ))}
          </div>
          <div className="qm-carousel qm-carousel2 absolute z-20 h-full">
            {[...Array(10).keys()].map((i) => (
              <div className="qm-carousel--block h-full md:w-[408px]" key={i}>
                <div
                  className="relative h-full w-full"
                  style={{
                    backgroundImage: "url('/qm-bg-2.webp')",
                    backgroundSize: "auto 70px",
                    backgroundRepeat: "repeat",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="quest-container-dragons absolute left-0 top-0 z-20 h-full w-full" />
        <div className="relative z-30 grid-cols-3 gap-4 md:grid md:pb-4">
          <ModalQuest1 isOpen={q1Open} setIsOpen={setQ1Open} />
          <div
            className="quest-item mb-4 md:mb-0"
            onClick={() => setQ1Open(true)}
          >
            <div
              className="quest-item--sketch q-global-brand z-10"
            />
            <div className="text-subhead z-20 rounded-3xl px-4 py-3 text-white">
              Global Brand
            </div>
          </div>
          <ModalQuest2 isOpen={q2Open} setIsOpen={setQ2Open} />
          <div
            className="quest-item mb-4 md:mb-0"
            onClick={() => setQ2Open(true)}
          >
            <div
              className="quest-item--sketch q-metaverse"
            />
            <div className="text-subhead z-20 rounded-3xl px-4 py-3 text-white">
              Metaverse
            </div>
          </div>
          <ModalQuest3 isOpen={q3Open} setIsOpen={setQ3Open} />
          <div
            className="quest-item mb-4 md:mb-0"
            onClick={() => setQ3Open(true)}
          >
            <div
              className="quest-item--sketch q-pushing"
            />
            <div className="text-subhead z-20 rounded-3xl px-4 py-3 text-white">
              Pushing Boundaries
            </div>
          </div>
        </div>
        <div className="relative z-30 grid-cols-2 gap-4 md:grid md:gap-3">
          <ModalQuest4 isOpen={q4Open} setIsOpen={setQ4Open} />
          <div
            className="quest-item quest-item-r2 mb-4 md:mb-0"
            onClick={() => setQ4Open(true)}
          >
            <div
              className="quest-item--sketch q-vision"
            />
            <div className="text-subhead z-20 rounded-3xl px-4 py-3 text-white">
              Vision + Goals
            </div>
          </div>
          <ModalQuest5 isOpen={q5Open} setIsOpen={setQ5Open} />
          <div
            className="quest-item quest-item-r2 mb-4 md:mb-0"
            onClick={() => setQ5Open(true)}
          >
            <div
              className="quest-item--sketch q-building"
            />
            <div className="text-subhead z-20 rounded-3xl px-4 py-3 text-white">
              Building
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeQuestMap.propTypes = {}

export default HomeQuestMap
