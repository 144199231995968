import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
import solidity from "react-syntax-highlighter/dist/cjs/languages/prism/solidity"
import prism from "react-syntax-highlighter/dist/cjs/styles/prism/prism"
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism"

const ModalDialog = (props) => {
  SyntaxHighlighter.registerLanguage("solidity", solidity)

  const { isOpen, setIsOpen } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[60]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div
            className="fixed inset-0 top-[6%] z-50 mx-auto h-[88%] w-[60%] max-w-[960px] overflow-hidden rounded-2xl px-12 text-left font-sans text-white shadow-xl"
            style={{
              background: "rgba(25, 23, 28, 0.98)",
              border: "48px solid rgba(0,0,0,0)",
            }}
          >
            <div className="h-full w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="opacity-96 flex h-full w-full transform flex-col transition-all">
                  <Dialog.Title
                    as="h3"
                    className="relative pb-10 pt-2 text-[2.2vmin]"
                  >
                    Technical Specs — $GEMZ
                    <button
                      type="button"
                      className="
                          absolute 
                          top-4
                          right-0
                          inline-flex
                          justify-center 
                          rounded-md 
                          border 
                          border-transparent 
                          bg-black
                          px-4
                          py-2 
                          text-sm 
                          font-medium 
                          text-gray-200 
                          opacity-50
                          transition-opacity
                          hover:text-white
                          hover:opacity-80
                          focus:outline-none "
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </Dialog.Title>
                  <div className="relative w-full overflow-y-auto">
                    <div className="mt-2">
                      <h1
                        id="modal-top"
                        className="mb-6 max-w-[660px] border-b-[1px] border-gray-600 pb-4 text-[1.8vmin]"
                      >
                        $GEMZ
                      </h1>
                      <p className="max-w-[660px] pb-4 leading-[2]">
                        The ERC20 tokens that power our ecosystem are called
                        $GEMZ; staking a Magic Folk NFT is the only way to
                        acquire $GEMZ.
                      </p>
                      <SyntaxHighlighter language="solidity" style={oneDark}>
                        {`
function enableTransfers() public onlyRole(DAO_ROLE) {
  require(_transferLock, "ALREADY_ENABLED");
  _transferLock = false;
}
                        `}
                      </SyntaxHighlighter>

                      <p className="max-w-[660px] pt-4 pb-4 leading-[2]">
                        The DAO address can only be set once. Nobody can bypass
                        this safeguard by giving themselves the DAO role or by
                        setting the DAO address as their own.
                      </p>
                      <SyntaxHighlighter language="solidity" style={oneDark}>
                        {`
function setDAO(address _DAO) public onlyRole(DEFAULT_ADMIN_ROLE) {
  require(address(DAO) == address(0), "DAO_ALREADY_SET");
  DAO = IGovernor(_DAO);
  _grantRole(DAO_ROLE, _DAO);
}
                        `}
                      </SyntaxHighlighter>
                      <h2 className="pb-6 pt-4 text-[1.6vmin]">
                        If $GEMZ can't be transferred, how can I use them in the
                        Magic Marketplace?
                      </h2>

                      <p className="max-w-[660px] pb-12 leading-[2]">
                        If you're wondering this then you're probably also
                        wondering how you could possibly earn them in the first
                        place when they can't even be transferred from one
                        account to another.
                      </p>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        The total supply of $GEMZ starts at zero. There is no
                        allocation/pre-minting for the dev team or early
                        investors. When you claim $GEMZ from your NFT, they're
                        minted directly to your address by the Magic Folk
                        contract. When you spend them at the Magic Marketpalce
                        they're burned. Nothing ever needs to be transferred
                        between accounts.
                      </p>

                      <h2 className="pb-6 text-[1.6vmin]">
                        How can you prevent an ERC20 token from being
                        transferred?
                      </h2>
                      <p className="max-w-[660px] pb-4 leading-[2]">
                        The code for this is rather straightforward:
                      </p>
                      <SyntaxHighlighter language="solidity" style={oneDark}>
                        {`
function transfer(
  address to, 
  uint256 amount
) public override returns (bool) {
  require(!_transferLock, "TRANSFERS_LOCKED");
  return super.transfer(to, amount);
}
                        `}
                      </SyntaxHighlighter>
                      <SyntaxHighlighter language="solidity" style={oneDark}>
                        {`
function transferFrom(
  address from, 
  address to, 
  uint256 amount
) public override returns (bool) {
  require(!_transferLock, "TRANSFERS_LOCKED");
  return super.transferFrom(from, to, amount);
}
                        `}
                      </SyntaxHighlighter>

                      <p className="max-w-[660px] pb-6 pt-4 leading-[2]">
                        Transfers are reverted unless the _transferLock
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          _transferLock
                        </code>{" "}
                        variable is set to
                        <code className="mx-1 rounded-lg bg-gray-700 px-2 py-1">
                          false
                        </code>
                        .
                      </p>

                      <h2 className="pb-6 text-[1.6vmin]">
                        Why should I care about acquiring $GEMZ if I can't sell
                        them?
                      </h2>
                      <p className="max-w-[660px] pb-8 leading-[2]">
                        Our development team has over 30 years of combined
                        experience. We want to build tools that give users a
                        real edge in today's NFT market. Some of these tools
                        could cost upwards of 1 ETH per year to subscribe to,
                        but holders of Magic Folk NFTs will be able to access
                        them with $GEMZ, as long as they've equipped enough
                        items to earn a reasonable amount of $GEMZ per day.
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
