import { checkIfIOS } from "../lib/frontend-helpers"
import { gsap } from "gsap"
import { useAppContext } from "../context/state"
import { useRef, useEffect, useState } from "react"
import { useTheme } from "next-themes"
import lDarkModeToggleData from "../public/l-dark-mode-toggle.json"
import lottie from "lottie-web"
import VideoBlob from "../components/VideoBlob"

const HomeFactions = (props) => {
  const { factionEntered } = props
  const { theme, setTheme } = useTheme()
  const [hoverTheme, setHoverTheme] = useState("light")
  const [isIOS, setIsIOS] = useState(false)
  const lThemeToggleContainer = useRef(null)
  const lThemeToggle = useRef(null)
  const wizardRef = useRef()
  const warlockRef = useRef()
  const factionsToggle = useRef()
  const factionContainer = useRef()

  const globalState = useAppContext()
  const { setFactionChosen } = globalState
  const SSR = typeof window === "undefined"

  // toggle only on the factions page
  useEffect(() => {
    if (lThemeToggleContainer.current) {
      lThemeToggle.current = lottie.loadAnimation({
        container: lThemeToggleContainer.current,
                loop: false,
        autoplay: false,
        animationData: lDarkModeToggleData,
      })

      return () => lThemeToggle.current?.destroy()
    }
  }, [lThemeToggleContainer.current])

  useEffect(() => {
    if (globalState.factionChosen === true) enterPostFactions()
  }, [theme, globalState.factionChosen])

  useEffect(() => {
    lThemeToggle.current?.setDirection(hoverTheme === "dark" ? 1 : -1)
    lThemeToggle.current?.play()
  }, [hoverTheme])

  const enterPostFactions = () => {
    warlockRef.current.classList.remove("on")
    warlockRef.current.classList.remove("off")
    wizardRef.current.classList.remove("on")
    wizardRef.current.classList.remove("off")

    document.querySelector(".p-factions").classList.add("faction-chosen")

    if (theme === "dark") {
      wizardRef.current.classList.add("not-chosen")
      wizardRef.current.classList.remove("chosen")

      warlockRef.current.classList.add("chosen")
      warlockRef.current.classList.remove("not-chosen")
    } else {
      wizardRef.current.classList.add("chosen")
      wizardRef.current.classList.remove("not-chosen")

      warlockRef.current.classList.add("not-chosen")
      warlockRef.current.classList.remove("chosen")
    }

    document.querySelector(".header-secondary").style.opacity = 1
    document.querySelector(".p-factions").classList.remove("z-infront")

    document.querySelector(".fog-enter").classList.add("start")
    document
      .querySelectorAll(".p-intro")
      .forEach((e) => e.classList.add("start"))
  }

  const chooseFaction = (side) => {
    setTheme(side)
    setFactionChosen(true)
  }
  
  const addFocus = (e, which) => {
    if (factionEntered === false) return
    if (globalState.factionChosen === true) return
    e.stopPropagation()

    if (which === "wizard") {
      setHoverTheme("light")
      factionsToggle.current.classList.add("light")
      factionsToggle.current.classList.remove("dark")
      wizardRef.current.classList.add("on")
      wizardRef.current.classList.remove("off")
      warlockRef.current.classList.remove("on")
      warlockRef.current.classList.add("off")
    } else {
      setHoverTheme("dark")
      factionsToggle.current.classList.add("dark")
      factionsToggle.current.classList.remove("light")
      warlockRef.current.classList.add("on")
      warlockRef.current.classList.remove("off")
      wizardRef.current.classList.remove("on")
      wizardRef.current.classList.add("off")
    }
  }

  useEffect(() => {
    if (SSR) return

    setIsIOS(checkIfIOS(window))

    gsap.set(".ball", { xPercent: -50, yPercent: -50 })

    const ball = document.querySelector(".ball")
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    const mouse = { x: pos.x, y: pos.y }
    const speed = 0.2

    const xSet = gsap.quickSetter(ball, "x", "px")
    const ySet = gsap.quickSetter(ball, "y", "px")

    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x
      mouse.y = e.y
    })

    gsap.ticker.add(() => {
      try {
        if ([undefined, null].includes(factionContainer.current)) return
        if (
          factionContainer.current.classList.contains("faction-chosen") === true
        )
          return

        // adjust speed for higher refresh monitors
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())

        pos.x += (mouse.x - pos.x) * dt
        pos.y += (mouse.y - pos.y) * dt
        xSet(pos.x)
        ySet(pos.y)
      } catch (error) {}
    })
  }, [SSR])

  return (
    <div
      ref={factionContainer}
      className="p-factions z-infront fixed left-0 top-0 z-10 h-screen w-screen overflow-hidden"
    >
      {/* follow cursor */}
      <div className="ball text-p z-50 flex flex-col items-center justify-center text-center font-sans text-sm font-medium uppercase leading-5 text-white shadow-xl">
        <span className="block" style={{ transform: "rotate(-5deg)" }}>
          Choose
        </span>
        <span className="block">a Side</span>
      </div>
      <div
        className="no-select factions-toggle absolute left-1/2 z-30 mx-auto mt-[8vh] w-[9vw] cursor-pointer"
        ref={factionsToggle}
      >
        <div
          className="relative -left-1/2 w-full"
          ref={lThemeToggleContainer}
        />
      </div>
      <div
        ref={wizardRef}
        onMouseOver={(e) => addFocus(e, "wizard")}
        onClick={() => chooseFaction("light")}
        className="
            faction
            factions-wizard
            absolute 
            -left-[20vw] 
            h-full 
            w-[70vw]
          "
      >
        <div className="half absolute h-full w-full">
          <div className="factions-inner absolute right-0 h-full w-[70%]">
            <h1 className="font-recoleta relative z-10 mx-auto mt-[17.5vmin] block text-center text-[9.6vmax] text-white">
              Wizard
              <span className="serif absolute -top-[0] block w-full text-[24px] font-light italic opacity-20">
                (light mode)
              </span>
            </h1>
            <div
              className="absolute left-[80%] z-30 block w-20"
              style={{ transform: "translate3d(0, 24vw, 0)" }}
            >
              <img
                className="factions-flower-r relative w-full"
                src="/flower-1r.webp"
                alt=""
              />
            </div>
            <div className="absolute -left-[3%] block w-28">
              <img
                className="factions-flower-l relative w-full"
                src="/flower-1l.webp"
                alt=""
              />
            </div>
            <div
              className="
                factions-mf
                absolute
                bottom-[0]
                left-[50%]
                z-20
                h-full
                md:-bottom-[25vw]
                "
            >
              <div className="relative h-full w-full">
                <div className="factions-mf-container relative h-full w-full">
                  <VideoBlob
                    classNames="
                      factions-mf-img 
                      absolute
                      bottom-0
                      left-0
                      block
                      w-full factions-mf-video"
                    autoPlay={isIOS}
                    videoSrc={`/accessories-light`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={warlockRef}
        onMouseOver={(e) => addFocus(e, "warlock")}
        onClick={() => chooseFaction("dark")}
        className="
            faction
            factions-warlock 
            absolute 
            -right-[20vw] 
            h-full 
            w-[70vw]
          "
      >
        <div className="half absolute h-full w-full">
          <div className="factions-inner absolute left-0 h-full w-[70%]">
            <h1 className="font-recoleta relative z-10 mx-auto mt-[17.5vmin] block text-center text-[9.6vmax] text-white">
              Warlock
              <span className="serif absolute -top-[0px] block w-full text-[24px] font-light italic opacity-20">
                (dark mode)
              </span>
            </h1>
            <div className="absolute left-[90%] top-[70%] z-30 block w-20 opacity-90">
              <img
                className="factions-fireball-r relative w-full"
                src="/fireball-1r.webp"
                alt=""
              />
            </div>
            <div className="absolute left-[50%] top-[61vmin] block w-14 opacity-40">
              <img
                className="factions-fireball-l relative w-full"
                src="/fireball-1l.webp"
                alt=""
              />
            </div>
            <div
              className="
                factions-mf
                absolute
                bottom-[2vw]
                left-[50%]
                z-20
                h-full
                md:-bottom-[22vw]
                "
            >
              <div className="relative h-full w-full">
                <div className="factions-mf-container relative h-full w-full">
                  <VideoBlob
                    classNames="
                      factions-mf-img 
                      absolute
                      bottom-0
                      left-0
                      block
                      w-full factions-mf-video"
                    autoPlay={isIOS}
                    videoSrc={`/accessories-dark`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="fixed bottom-[10vh] left-0 h-[100vh] w-full"
        style={{ perspective: "100px" }}
      >
        <div className="smoke fog-enter absolute left-0 bottom-0 h-full w-full">
          <div
            data-scroll
            data-scroll-speed="-2"
            className="fog1 absolute left-0 top-[60vh] w-[69.4vw] pt-[50%] opacity-60"
            style={{
              backgroundImage: "url('/fog1.webp')",
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div
            data-scroll
            data-scroll-speed="-3"
            className="fog2 absolute left-0 top-[65vh] w-[41.6vw] pt-[50%] opacity-80"
            style={{
              backgroundImage: "url(/fog1.webp)",
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div className="fog3 absolute left-0 top-[75vh] w-[62.5vw] pt-[50%] opacity-70">
            <span
              data-scroll
              data-scroll-speed="6"
              className="relative block h-full w-full"
              style={{
                backgroundImage: "url(/fog1.webp)",
                backgroundSize: "100% auto",
                backgroundRepeat: "no-repeat",
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeFactions.propTypes = {}

export default HomeFactions
