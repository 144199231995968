import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useState, useEffect } from "react"

const ModalDialog = (props) => {
  const { isOpen, setIsOpen, title, content } = props

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 mx-auto flex items-center justify-center  overflow-hidden">
            <div className="quest-modal-bg relative w-[88%] max-w-[1500px] overflow-y-auto rounded-2xl bg-[#F1EFEA] p-4 text-left font-sans text-black shadow-xl md:pl-12 vpt:h-[48%] vpt:max-h-[900px] vld:h-[88%] vld:max-h-[1000px]">
              <div
                className="absolute left-0 top-0 z-10 h-full w-full opacity-10 md:opacity-100"
                style={{
                  backgroundImage: "url(/qm-pushing--hover.svg)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "42% auto",
                  backgroundPosition: "right center",
                }}
              />
              <div className="relative z-20 h-full w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="opacity-96 flex transform flex-col text-sm transition-all">
                    <Dialog.Title as="h3" className="text-title pb-6 pt-10">
                      Pushing Boundaries ⚓
                      <span className="text-light block font-sans text-sm italic text-black opacity-50">
                        &ldquo;Simply pushing harder within old boundaries will
                        not do.&rdquo; - Karl E. Weick
                      </span>
                      <button
                        type="button"
                        className="
                          absolute 
                          top-3
                          right-3
                          w-6
                          focus:outline-none "
                        onClick={closeModal}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.28572 0L0 4.28572L7.71429 12L0 19.7143L4.28572 24L12 16.2857L19.7143 24L24 19.7143L16.2857 12L24 4.28572L19.7143 0L12 7.71429L4.28572 0Z"
                            fill="#1D1A25"
                          />
                        </svg>
                      </button>
                    </Dialog.Title>
                    <div className="relative w-full overflow-y-auto">
                      <div className="md:w-[55%]">
                        <p className="text-summary font-normal">
                          We are on a mission to make a splash in the web3
                          space. Inspired to innovate, dedicated to create,
                          pushing the boundaries of whats possible.
                        </p>

                        <h3 className="text-subhead pt-6">
                          NFT Customization + Token Gated Collab Assets
                          <span className="quest-progress bg-[#8FC34D]">
                            Complete
                          </span>
                          <span className="quest-progress bg-[#5E8927]">
                            Ongoing
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          We believe in long term partnerships. That&rsquo;s we
                          we have set out to create unique, equipable assets for
                          our long-term partners. You will have to hold a
                          partner collection's NFT in order to acquire these
                          limited edition items.
                        </p>

                        <h3 className="text-subhead pt-6">
                          Marketplace
                          <span className="quest-progress bg-[#8FC34D]">
                            Complete
                          </span>
                          <span className="quest-progress bg-[#5E8927]">
                            Ongoing
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          Our feature rich, state of the art, scalable
                          marketplace is available right on mint. Initially you
                          will be able to burn $GEMZ to acquire unique Pets,
                          Weapons, Magic Items, Coal and Allowlist spots. In the
                          future, we would like to add more things such as
                          holder exclusive tools, services, merch and more!
                        </p>

                        <h3 className="text-subhead pt-6">
                          Interactive Website
                          <span className="quest-progress bg-[#8FC34D]">
                            Complete
                          </span>
                          <span className="quest-progress bg-[#5E8927]">
                            Ongoing
                          </span>
                        </h3>
                        <p className="text-p pt-2 font-normal">
                          User experience is one of our core values, thats why
                          we&rsquo;ve taken the time to create a unique
                          experience on our homepage.
                        </p>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ModalDialog
